import React, { Component } from "react";
import {
  Col,
  Row,
  Card,
  Table,
  // Input,
  InputNumber,
  Icon,
  message,
  Button,
  Tooltip,
  Modal,
  Checkbox,
  Switch,
  Dropdown,
  Menu,
} from "antd";
import PLACEHOLDER_PRODUCT_IMAGE from "../../../assets/images/icons/image_placeholder.png";
import { ReactComponent as ExternalLinkIcon } from "../../../assets/images/icons/external_link.svg";
import axios from "axios";
import moment from "moment";
import "./index.scss";
import LightBoxModal from "./LightBoxModal";
import { connect } from "react-redux";
const { confirm } = Modal;

class ProductCard extends Component {
  state = {
    product: this.props.product,
    showVariantDetails: false,
    showImageModal: {
      visible: false,
      index: 0,
      files: [],
    },
  };

  toggleShowImageModal(i = 0, files = []) {
    this.setState({
      showImageModal: {
        visible: !this.state.showImageModal.visible,
        index: i,
        files: files,
      },
    });
  }

  updateAttribute = (key, val) => {
    // api call
    axios({
      url: "products/update_product_attributes",
      method: "Post",
      data: {
        id: this.state.product._id["$oid"],
        attribute: key,
        value: val,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState((state) => ({
            product: {
              ...state.product,
              product_attributes: {
                ...state.product.product_attributes,
                [key]: val,
              },
            },
          }));
          message.success("Updated Successfully!");
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  toggleDetails() {
    let allFieldsSet = true;
    if (this.state.showVariantDetails) {
      this.state.product.variants.forEach((v) => {
        if (v.vendor_data.unavailable !== 1) {
          if (
            (!v.vendor_data.price || v.vendor_data.price === "") &&
            (!v.vendor_data.global_price || v.vendor_data.global_price === "")
          ) {
            allFieldsSet = false;
          }

          // if (!v.vendor_data.price || v.vendor_data.price === "") {
          //   allFieldsSet = false;
          // }
          // if (!v.vendor_data.hsn || v.vendor_data.hsn === "") {
          //   allFieldsSet = false;
          // }
        }
      });
      if (!allFieldsSet) {
        message.error("Please enter price and HSN for all variants!");
        return;
      }
    }
    this.setState(
      {
        showVariantDetails: !this.state.showVariantDetails,
      },
      () => {
        if (!this.state.showVariantDetails) {
          message.loading("Saving", 0);
          axios({
            url: "/products/vendor_update_product",
            method: "post",
            data: {
              id: this.state.product._id["$oid"],
              data: this.state.product.variants,
            },
          })
            .then((res) => {
              message.destroy();
              return res;
            })
            .then((res) => {
              if (res.data.success === 1) {
                message.success("Saved Successfully!");
              } else {
                message.error(res.data.msg);
              }
            })
            .catch((e) => {
              message.error(e.message);
            });
        }
      }
    );
  }

  setVendorData(id, key, value) {
    this.setState({
      product: {
        ...this.state.product,
        variants: this.state.product.variants.map((v) => {
          if (v.skuPropIds === id) {
            if (key.indexOf("dimensions.") === -1) {
              return {
                ...v,
                vendor_data: {
                  ...v.vendor_data,
                  [key]: value,
                },
              };
            } else {
              return {
                ...v,
                vendor_data: {
                  ...v.vendor_data,
                  dimensions: {
                    ...v.vendor_data.dimensions,
                    [key.split(".")[1]]: value,
                  },
                },
              };
            }
          } else {
            return v;
          }
        }),
      },
    });
  }

  markUnfulfillable = () => {
    const unfulfillable = this.state.product.unfulfillable;
    confirm({
      title: "Are you sure?",
      content: `Are you sure you want to mark this product as ${
        unfulfillable === 1 ? "fulfillable" : "unfulfillable"
      }?`,
      okText: "Yes",
      onOk: () => {
        axios({
          url: "/products/toggle_unfulfillable",
          method: "post",
          data: {
            id: this.state.product._id["$oid"],
          },
        })
          .then((res) => {
            message.destroy();
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              message.success("Updated Successfully!");
              this.setState((state) => ({
                product: {
                  ...state.product,
                  unfulfillable:
                    (state.product.unfulfillable || 0) === 1 ? 0 : 1,
                },
              }));
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
          });
      },
      onCancel() {},
    });
  };

  cloneFirstRowData = () => {
    const firstRow = this.state.product.variants[0];
    const updateVariants = this.state.product.variants.map((v, i) => {
      if (i === 0) {
        return v;
      } else {
        return {
          ...v,
          vendor_data: {
            ...v.vendor_data,
            // weight: firstRow.vendor_data.weight,
            // dimensions: firstRow.vendor_data.dimensions,
            // type: firstRow.vendor_data.type,
            price: firstRow.vendor_data.price,
            global_price: firstRow.vendor_data.global_price,
          },
        };
      }
    });
    this.setState(
      (state) => ({
        product: {
          ...state.product,
          variants: [],
        },
      }),
      () => {
        this.setState((state) => ({
          product: {
            ...state.product,
            variants: updateVariants,
          },
        }));
      }
    );
  };

  render() {
    let product = this.state.product;
    const geo = this.props.user.user_geo;

    if (product.dimensions === undefined) {
      product.dimensions = {};
    }
    if (product.product_attributes === undefined) {
      product.product_attributes = {};
    }
    if (product.product_attributes.fragile === undefined) {
      product.product_attributes.fragile = 0;
    }
    if (product.product_attributes.in_box === undefined) {
      product.product_attributes.in_box = 0;
    }
    if (product.product_attributes.battery === undefined) {
      product.product_attributes.battery = 0;
    }
    if (product.product_attributes.bluetooth === undefined) {
      product.product_attributes.bluetooth = 0;
    }
    product.variants = product.variants.map((v) => {
      if (v.vendor_data === undefined) {
        v.vendor_data = {};
      }
      if (v.vendor_data.sku === undefined) {
        v.vendor_data.sku = `${product.productId}-${v.skuPropIds.replace(
          /,/g,
          "_"
        )}`;
      }
      if (v.vendor_data.weight === undefined) {
        v.vendor_data.weight = product.productWeight;
      }
      if (v.vendor_data.dimensions === undefined) {
        v.vendor_data.dimensions = {};
      }
      if (v.vendor_data.dimensions.w === undefined) {
        v.vendor_data.dimensions.w = product.dimensions.w;
      }
      if (v.vendor_data.dimensions.d === undefined) {
        v.vendor_data.dimensions.d = product.dimensions.d;
      }
      if (v.vendor_data.dimensions.h === undefined) {
        v.vendor_data.dimensions.h = product.dimensions.h;
      }
      return v;
    });

    if (product.product_attributes === undefined) {
      product.product_attributes = {};
    }
    if (product.product_attributes.fragile === undefined) {
      product.product_attributes.fragile = 0;
    }
    if (product.product_attributes.in_box === undefined) {
      product.product_attributes.in_box = 0;
    }
    if (product.product_attributes.bluetooth === undefined) {
      product.product_attributes.bluetooth = 0;
    }
    if (product.product_attributes.battery === undefined) {
      product.product_attributes.battery = 0;
    }

    let columns = [
      {
        title: "Available",
        dataIndex: "vendor_data.unavailable",
        width: 0,
        render: (unavailable, r) => (
          <Checkbox
            checked={unavailable === 1 ? false : true}
            onChange={(e) =>
              this.setVendorData(
                r.skuPropIds,
                "unavailable",
                e.target.checked ? 0 : 1
              )
            }
          />
        ),
      },

      {
        title: ``,
        dataIndex: "img",
        render: (_, sku, __) => {
          return sku.img ? (
            <span className="variant-image">
              <img
                src={sku.img}
                alt={`SKU ${sku.vskuid}`}
                style={{ maxWidth: "50px" }}
              />
              <Icon
                type="zoom-in"
                onClick={() =>
                  this.toggleShowImageModal(
                    0,
                    typeof sku.img === "string" ? [sku.img] : sku.img
                  )
                }
              />
            </span>
          ) : (
            ""
          );
        },
      },

      {
        title: "Product Specs",
        dataIndex: "allspecs",
      },
      {
        title: "Product SKU",
        dataIndex: "vendor_data.sku",
        key: "product_sku",

        render: (sku, r) => {
          return (
            <span>
              {sku}{" "}
              {r.aliexpress_links && r.aliexpress_links.length > 0 && (
                <Dropdown
                  overlay={
                    <Menu>
                      {r.aliexpress_links.map((l, i) => (
                        <Menu.Item key={i}>
                          <a target="_blank" rel="noopener noreferrer" href={l}>
                            {`Link ${i + 1}`}
                          </a>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                    href="#!"
                  >
                    <Icon type="link" />
                  </a>
                </Dropdown>
              )}
            </span>
          );
        },
      },
      // {
      //   title: (
      //     <div style={{ textAlign: "center" }}>
      //       Weight
      //       <br />
      //       (kgs)
      //     </div>
      //   ),
      //   dataIndex: "vendor_data.weight",
      //   render: (text, r) => (
      //     <InputNumber
      //       defaultValue={text}
      //       onScroll={(e) => console.log(e)}
      //       onBlur={(e) =>
      //         this.setVendorData(r.skuPropIds, "weight", e.target.value)
      //       }
      //       style={{ maxWidth: 120 }}
      //     />
      //   ),
      // },
      // {
      //   title: (
      //     <div style={{ textAlign: "center" }}>
      //       Dimensions (H * W * D)
      //       <br />
      //       (cm * cm * cm)
      //     </div>
      //   ),
      //   width: 300,
      //   dataIndex: "vendor_data.dimensions",
      //   render: (dimensions = {}, r) => (
      //     <span>
      //       <InputNumber
      //         defaultValue={dimensions.h}
      //         onBlur={(e) =>
      //           this.setVendorData(r.skuPropIds, "dimensions.h", e.target.value)
      //         }
      //         style={{ maxWidth: 70 }}
      //       />{" "}
      //       X{" "}
      //       <InputNumber
      //         defaultValue={dimensions.w}
      //         onBlur={(e) =>
      //           this.setVendorData(r.skuPropIds, "dimensions.w", e.target.value)
      //         }
      //         style={{ maxWidth: 70 }}
      //       />{" "}
      //       X{" "}
      //       <InputNumber
      //         defaultValue={dimensions.d}
      //         onBlur={(e) =>
      //           this.setVendorData(r.skuPropIds, "dimensions.d", e.target.value)
      //         }
      //         style={{ maxWidth: 70 }}
      //       />
      //     </span>
      //   ),
      // },

      {
        title: "Vendor's Price (USD)",
        dataIndex:
          geo === "in" ? "vendor_data.price" : "vendor_data.global_price",
        render: (text, r, index) => (
          <div className="clonerowprice">
            <InputNumber
              defaultValue={text}
              onBlur={(e) =>
                this.setVendorData(
                  r.skuPropIds,
                  geo === "in" ? "price" : "global_price",
                  e.target.value
                )
              }
              style={{ maxWidth: 70 }}
            />
            {index === 0 && (
              <>
                <br />
                <a
                  href="#!"
                  onClick={() => this.cloneFirstRowData()}
                  style={{ marginTop: "4px" }}
                >
                  <Icon type="copy" /> Clone Row
                </a>
              </>
            )}
          </div>
        ),
      },

      // {
      //   title: "HSN",
      //   dataIndex: "vendor_data.hsn",
      //   render: (text, r, index) =>
      //     this.props.catalog ? (
      //       text
      //     ) : (
      //       <Input
      //         defaultValue={text}
      //         onBlur={(e) =>
      //           this.setVendorData(r.skuPropIds, "hsn", e.target.value)
      //         }
      //       />
      //     ),
      // },
    ];

    columns = [
      ...columns,
      {
        title: "",
        dataIndex: "vendor_data.sku",
        render: (text) => (
          <>
            <a
              href={`/products/print_sku?sku=${text}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Tooltip title="Print Product SKU Code">
                <Icon type="barcode" size="large" />
              </Tooltip>
            </a>
          </>
        ),
      },
    ];

    let prices = {
      vendor: {
        min: product.variants.reduce(
          (prev, curr) =>
            geo === "global"
              ? parseFloat(curr.vendor_data.global_price) < prev
                ? parseFloat(curr.vendor_data.global_price)
                : prev
              : parseFloat(curr.vendor_data.price) < prev
              ? parseFloat(curr.vendor_data.price)
              : prev,
          Infinity
        ),
        max: product.variants.reduce(
          (prev, curr) =>
            geo === "global"
              ? parseFloat(curr.vendor_data.global_price) > prev
                ? parseFloat(curr.vendor_data.global_price)
                : prev
              : parseFloat(curr.vendor_data.price) > prev
              ? parseFloat(curr.vendor_data.price)
              : prev,
          0
        ),
        avg:
          product.variants.reduce(
            (acc, c) =>
              geo === "global"
                ? acc + parseFloat(c.vendor_data.global_price)
                : acc + parseFloat(c.vendor_data.price),
            0
          ) / product.variants.length,
      },
    };

    return (
      <Card style={{ margin: "30px 0" }} className="product-card">
        <Row className="card-header">
          <Col xs={24} md={6}>
            <div className="product-details">
              <div
                className="product-image-container"
                style={{
                  background: `url(${
                    product.gallery && product.gallery[0]
                      ? product.gallery[0]
                      : PLACEHOLDER_PRODUCT_IMAGE
                  }) no-repeat center center`,
                }}
              />
              <Icon
                type="zoom-in"
                style={{
                  marginRight: "10px",
                  marginTop: "20%",
                  color: "black",
                }}
                onClick={() => this.toggleShowImageModal(0, product.gallery)}
              />
              <div className="product-id-added">
                <p>
                  <b>Catalog Product ID: </b> <br />{" "}
                  <span className="blue-text">{product.productId}</span>
                </p>
                <p className="grey-text">
                  Added On: {moment(product.added).format("DD MMM, YYYY")}
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} md={9} style={{ padding: "0 15px" }}>
            <p>
              <b>Catalog Product Name: </b>
            </p>
            <p className="grey-text">{product.name}</p>
          </Col>
          <Col xs={24} md={5}>
            <p>
              <b>Avg. Vendor Price:</b>{" "}
              <span className="grey-text">
                {Number.isNaN(prices.vendor.avg) ? (
                  "N.A."
                ) : (
                  <Tooltip
                    title={`$${prices.vendor.min.toFixed(
                      2
                    )} - $${prices.vendor.max.toFixed(2)}`}
                  >
                    ${prices.vendor.avg.toFixed(2)}
                  </Tooltip>
                )}
              </span>
            </p>
            <p>
              <b>No. of Variants:</b>{" "}
              <span className="grey-text">{product.variants.length}</span>
            </p>
          </Col>
          <Col xs={24} md={4}>
            <br />
            <p style={{ marginBottom: 14 }}>
              <a
                href={product.aliexurl}
                className="pink-text"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Original Product{" "}
                <Icon
                  component={ExternalLinkIcon}
                  style={{ color: "inherit", fontSize: 14 }}
                />
              </a>
            </p>
            <p>
              <Tooltip title="Unfulfillable products are the products that cannot be shipped to India">
                <b>Fulfillable</b>{" "}
                <Switch
                  checked={product.unfulfillable !== 1}
                  onChange={this.markUnfulfillable}
                  size="small"
                  style={{ marginLeft: 12 }}
                />
              </Tooltip>
            </p>
          </Col>
        </Row>
        {/* <Row>
          <h3 style={{ textAlign: "center" }}>Product Attributes</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginBottom: "10px",
            }}
          >
            <Checkbox
              checked={product.product_attributes.fragile === 1}
              onChange={(e) =>
                this.updateAttribute("fragile", e.target.checked ? 1 : 0)
              }
            >
              Fragile
            </Checkbox>
            <Checkbox
              checked={product.product_attributes.in_box === 1}
              onChange={(e) =>
                this.updateAttribute("in_box", e.target.checked ? 1 : 0)
              }
            >
              Comes with Box
            </Checkbox>
            <Checkbox
              checked={product.product_attributes.bluetooth === 1}
              onChange={(e) =>
                this.updateAttribute("bluetooth", e.target.checked ? 1 : 0)
              }
            >
              Bluetooth Enabled
            </Checkbox>
            <Checkbox
              checked={product.product_attributes.battery === 1}
              onChange={(e) =>
                this.updateAttribute("battery", e.target.checked ? 1 : 0)
              }
            >
              Battery Enabled
            </Checkbox>
          </div>
        </Row> */}
        <Row
          style={{ display: this.state.showVariantDetails ? "block" : "none" }}
        >
          <Table
            rowKey={(p) => p.skuPropIds}
            pagination={false}
            columns={columns}
            dataSource={product.variants}
            style={{ paddingBottom: 5 }}
            // scroll={{ x: 1300 }}
          />
        </Row>
        {this.state.showVariantDetails ? (
          <div style={{ background: "#F2F2F2", padding: "10px" }}>
            <div style={{ width: "fit-content", marginLeft: "auto" }}>
              <a
                href="#!"
                className="grey-text"
                onClick={() =>
                  this.setState({
                    showVariantDetails: !this.state.showVariantDetails,
                  })
                }
              >
                Close
              </a>{" "}
              &nbsp;{" "}
              <Button
                type="primary"
                onClick={() => this.toggleDetails(product)}
              >
                Save & Hide Variants
              </Button>
            </div>
          </div>
        ) : (
          <p
            style={{
              textAlign: "center",
              background: "#F2F2F2",
              margin: 0,
              padding: "10px 0",
            }}
          >
            <a
              href="#/"
              className="blue-text"
              onClick={() => this.toggleDetails(product)}
            >
              {" "}
              View Variant Details <Icon type="down" />
            </a>
          </p>
        )}
        <LightBoxModal
          toggleShowImageModal={() => this.toggleShowImageModal()}
          visible={this.state.showImageModal.visible}
          files={this.state.showImageModal.files}
          defaultIndex={this.state.showImageModal.index}
        />
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.auth.user,
});
export default connect(mapStateToProps)(ProductCard);
