import React from "react";
import { Button, Row, Col, Modal, InputNumber } from "antd";
import "./index.scss";

const ShipmentConfirmationModal = props => {
  let ordersToDisplay = [];
  if (props.id && props.orders[props.id]) {
    ordersToDisplay = Object.values(props.orders[props.id]).map(li => ({
      ...li,
      orderid: props.id
    }));
  } else {
    Object.keys(props.orders).forEach(orderid => {
      ordersToDisplay.push(
        ...Object.values(props.orders[orderid]).map(li => ({ ...li, orderid }))
      );
    });
  }

  return (
    <Modal
      className="orders-confirmation-modal"
      title="Create Shipment"
      visible={props.visible}
      closable={!props.saving}
      onCancel={props.onCancel}
      maskClosable={props.saving}
      width={860}
      footer={
        <Row>
          <Col xs={16} style={{ textAlign: "left" }}>
            <label style={{ fontWeight: "bold" }}>Enter Package Weight</label>
            <InputNumber
              value={props.weight}
              addonAfter="grams"
              style={{ width: 300, display: "inline-block", marginLeft: 15 }}
              onChange={e => props.onWeightChange(e)}
            />
          </Col>
          <Col xs={8}>
            <Button key="back" onClick={props.onCancel} disabled={props.saving}>
              Cancel
            </Button>{" "}
            <Button
              key="submit"
              type="primary"
              onClick={() => props.onConfirm()}
              loading={props.saving}
            >
              Create Shipment
            </Button>
          </Col>
        </Row>
      }
    >
      <div className="selected-orders">
        <Row className="header-row">
          <Col xs={3}>Order #</Col>
          <Col xs={17}>Product Details</Col>
          <Col xs={4} style={{ textAlign: "center" }}>
            Quantity
          </Col>
        </Row>
        {ordersToDisplay.map(order => (
          <Row
            key={`${order.orderid}_${order.lineitem_id}`}
            className="line-item"
          >
            <Col className="orderid" xs={3}>
              {props.id}
            </Col>
            <Col className="product-description" xs={17}>
              <Row className="line-item-product-detail">
                <Col xs={4}>
                  <span
                    className="line-item-img"
                    style={{ background: `url(${order.img})` }}
                  />
                </Col>
                <Col xs={20}>
                  <p className="blue-text line-item-name">{order.name}</p>
                  <p className="line-item-sku">SKU #{order.vendorsku}</p>
                </Col>
              </Row>
            </Col>
            <Col xs={4} style={{ textAlign: "center" }}>
              {order.quantity}
            </Col>
          </Row>
        ))}
      </div>
    </Modal>
  );
};

export default ShipmentConfirmationModal;
