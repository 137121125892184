import React, { Component } from "react";
import {
  Alert,
  Skeleton,
  Row,
  Modal,
  Icon,
  message,
  Pagination,
  Input,
  Empty,
  DatePicker,
  Button,
} from "antd";
import { ReactComponent as CircleCheck } from "../../../assets/images/icons/circle-check.svg";
import { ReactComponent as CircleCross } from "../../../assets/images/icons/circle-cross.svg";
import { ReactComponent as CirclePause } from "../../../assets/images/icons/circle-pause.svg";
import { ReactComponent as OpenOrderIcon } from "../../../assets/images/icons/open-order-icon.svg";
import { ReactComponent as NotFulfillIcon } from "../../../assets/images/icons/not-fulfill-icon.svg";
import axios from "axios";
import ShipmentCard from "../ShipmentCard";
import AddNoteModal from "./AddNoteModal";
import moment from "moment";
const confirm = Modal.confirm;
const { RangePicker } = DatePicker;

class Shipments extends Component {
  state = {
    orders: [],
    carriers: {},
    currencies: [],
    loading: true,
    error: false,
    errorMsg: "",
    filters: {
      search: "",
      startDate: "",
      endDate: "",
    },
    paging: {
      page: 1,
      per_page_count: 200,
    },
    notesModal: {
      visible: false,
      saving: false,
      id: undefined,
      cb: undefined,
    },
  };

  fetchUsers() {
    axios({
      url: "/orders/get_shipments",
      method: "post",
      data: {
        page: this.state.paging.page,
        per_page_count: this.state.paging.per_page_count,
        filters: this.state.filters,
        type: "shipments",
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            orders: res.data.orders,
            carriers: res.data.carriers,
            total: res.data.total,
            loading: false,
          });
        } else {
          this.setState({
            error: true,
            errorMsg: res.data.msg,
            loading: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.fetchUsers();
  }

  componentDidUpdate(pp) {
    if (pp.pageType !== this.props.pageType) {
      this.setState(
        {
          orders: [],
          selected: {},
          total: 0,
          loading: true,
          filters: {
            search: "",
            startDate: "",
            endDate: "",
          },
        },
        () => this.fetchUsers()
      );
    }
  }

  reloadList = () => {
    this.setState(
      {
        loading: true,
        orders: [],
      },
      () => this.fetchUsers()
    );
  };

  onFilterChange(filters) {
    this.setState(
      {
        filters: { ...this.state.filters, ...filters },
      },
      () => this.goToPage(1)
    );
  }

  markAsVerified(product) {
    if (product.qty === undefined || product.qty < 1) {
      message.error("Please enter a valid quantity!");
      return;
    }
    confirm({
      title: "Are you sure?",
      content: "Are you sure you want to mark this order as verified?",
      onOk: () => {
        message.success("Order Verified Successfully!");
        this.setState({
          orders: this.state.orders.filter(
            (p) => p._id["$oid"] !== product._id["$oid"]
          ),
        });
      },
      onCancel() {},
    });
  }

  setQty(p, qty) {
    this.setState({
      orders: this.state.orders.map((o) => {
        if (o._id["$oid"] === p._id["$oid"]) {
          return { ...o, qty };
        } else {
          return o;
        }
      }),
    });
  }

  goToPage(page) {
    this.setState(
      {
        loading: true,
        paging: {
          ...this.state.paging,
          page,
        },
      },
      () => this.fetchUsers()
    );
  }

  addNote = (value) => {
    const { note } = value;

    if (typeof note === "string" && note.length > 0) {
      this.setState(
        {
          notesModal: {
            ...this.state.notesModal,
            saving: true,
          },
        },
        () => {
          axios({
            url: "/orders/add_note",
            method: "post",
            data: {
              storeid: this.props.match.params.storeid,
              orderid: this.state.notesModal.id,
              note,
            },
          })
            .then((res) => {
              this.setState({
                notesModal: {
                  ...this.state.notesModal,
                  saving: false,
                },
              });
              if (res.data.success === 1) {
                message.success("Note added successfully.");
                if (this.state.notesModal.cb) {
                  this.state.notesModal.cb(res.data.note);
                }
                this.toggleNotesModal();
              } else {
                message.error("There was an error adding the note.");
              }
            })
            .catch((e) => {
              this.setState({
                notesModal: {
                  ...this.state.notesModal,
                  saving: false,
                },
              });
              message.error(e.message);
            });
        }
      );
    } else {
      message.error("Please enter a note to add.");
    }
  };

  toggleNotesModal(id = undefined, cb = undefined) {
    this.setState({
      notesModal: {
        visible: !this.state.notesModal.visible,
        id,
        cb,
      },
    });
  }

  refreshList = () => {
    this.setState(
      {
        loading: true,
        paging: {
          page: 1,
          per_page_count: 10,
        },
      },
      () => this.fetchUsers()
    );
  };

  render() {
    const PageWrapper = (children) => (
      <div className="store-users">
        <Row>
          <h2>
            Shipments
            <Button
              type="primary"
              disabled={this.state.loading}
              onClick={this.refreshList}
              size="small"
              style={{ marginLeft: 10 }}
            >
              Refresh
            </Button>
            <span style={{ float: "right" }}>
              <Input.Search
                style={{ width: 400 }}
                placeholder="Enter Keywords"
                enterButton="Search"
                onSearch={(value) => this.onFilterChange({ search: value })}
              />
            </span>
          </h2>
        </Row>
        <Row style={{ marginBottom: 20 }}>
          <h3>
            Your Shipments From{" "}
            <RangePicker
              value={[
                moment(this.state.filters.startDate).isValid()
                  ? moment(this.state.filters.startDate)
                  : null,
                moment(this.state.filters.endDate).isValid()
                  ? moment(this.state.filters.endDate)
                  : null,
              ]}
              onChange={(_, dates) =>
                this.onFilterChange({ startDate: dates[0], endDate: dates[1] })
              }
            />
            <span style={{ float: "right" }}>
              <a
                href="/orders/shipments_csv"
                target="_blank"
                rel="noreferrer noopener"
                className="ant-btn ant-btn-primary"
              >
                <Icon type="download" /> Download as CSV
              </a>
            </span>
          </h3>
        </Row>
        {children}
        <AddNoteModal
          visible={this.state.notesModal.visible}
          toggleModal={() => this.toggleNotesModal()}
          onConfirm={(value) => this.addNote(value)}
          saving={this.state.notesModal.sav}
          onCancel={() => this.toggleNotesModal()}
        />
      </div>
    );

    if (this.state.loading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={this.state.errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    const paymentStatusColors = {
      paid: "#73D13D",
      partially_paid: "#4569FF",
      unpaid: "#FF7A45",
      refunded: "#FF4D4F",
      partially_refunded: "#FFC53D",
    };
    const paymentStatusText = {
      paid: "Fully Paid",
      partially_paid: "Partially Paid",
      unpaid: "Pending",
      refunded: "Refunded",
      partially_refunded: "Partially Refunded",
    };
    const shipmentStatusColors = {
      manifested: "#FF7A45",
      transit: "#FFC53D",
      delivered: "#73D13D",
      returned: "#FF4D4F",
    };
    const shipmentStatusText = {
      manifested: "Manifested",
      transit: "In Transit",
      delivered: "Delivered",
      returned: "Returned",
    };
    const fulfillmentStatusColors = {
      shipped: "#73D13D",
      unshipped: "#FF4D4F",
      partial: "#FF7A45",
    };
    const fulfillmentStatusText = {
      shipped: "Fulfilled",
      unshipped: "Unfulfilled",
      partial: "Partially Fulfilled",
    };
    const vfOrderStatusColors = {
      verified: "#73D13D",
      verified_ordered: "#4569FF",
      not_fulfill: "#FF4585",
      hold: "#FF7A45",
      cancelled: "#FF4D4F",
      open: "#FFC53D",
    };
    const vfOrderStatusIcons = {
      verified: CircleCheck,
      verified_ordered: CircleCheck,
      not_fulfill: NotFulfillIcon,
      hold: CirclePause,
      cancelled: CircleCross,
      open: OpenOrderIcon,
    };
    const vfOrderStatusText = {
      verified: "Verified",
      verified_ordered: "Verified & Ordered",
      not_fulfill: "Do Not Fulfill",
      hold: "On Hold",
      cancelled: "Cancelled",
      open: "Open",
    };

    return PageWrapper(
      <React.Fragment>
        {this.state.orders.length > 0 ? (
          this.state.orders.map((order) => (
            <ShipmentCard
              key={order._id["$oid"]}
              {...this.props}
              vfOrderStatus={this.state.filters.vfOrderStatus}
              paymentStatusColors={paymentStatusColors}
              paymentStatusText={paymentStatusText}
              shipmentStatusColors={shipmentStatusColors}
              shipmentStatusText={shipmentStatusText}
              fulfillmentStatusColors={fulfillmentStatusColors}
              fulfillmentStatusText={fulfillmentStatusText}
              vfOrderStatusColors={vfOrderStatusColors}
              vfOrderStatusIcons={vfOrderStatusIcons}
              vfOrderStatusText={vfOrderStatusText}
              carriers={this.state.carriers}
              reloadList={this.reloadList}
              currencies={{ store_currency: "USD" }}
              order={order}
              toggleNotesModal={(id, cb) => this.toggleNotesModal(id, cb)}
            />
          ))
        ) : (
          <Empty />
        )}
        <Pagination
          showQuickJumper
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          defaultCurrent={this.state.paging.page}
          pageSize={this.state.paging.per_page_count}
          total={this.state.total}
          onChange={(page) => this.goToPage(page)}
        />
      </React.Fragment>
    );
  }
}

export default Shipments;
