import React, { Component } from "react";
import {
  Alert,
  Skeleton,
  Row,
  Button,
  Icon,
  Table,
  message,
  Input,
  Col,
  Form,
  Modal,
} from "antd";
import axios from "axios";
import moment from "moment";
import "./index.scss";

const { confirm } = Modal;

const CreateMasterShipmentForm = Form.create()(
  // eslint-disable-next-line
  class extends React.Component {
    componentDidUpdate(pp) {
      if (pp.visible !== this.props.visible) {
        const { setFieldsValue } = this.props.form;
        setFieldsValue({
          awbs: this.props.data.awbs
            ? this.props.data.awbs.map((awb) => awb.trim()).join("\n")
            : "",
          deadweight: this.props.data.deadweight,
          dimensions: this.props.data.dimensions,
        });
      }
    }

    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title="Master Shipment"
          okText="Save"
          onCancel={onCancel}
          onOk={onCreate}
          closable={!this.props.saving}
          maskClosable={false}
          destroyOnClose={true}
        >
          <Form layout="vertical">
            <Form.Item label="AWBs">
              {getFieldDecorator("awbs", {
                rules: [
                  {
                    required: true,
                    message: "Please input the AWBs for this shipment!",
                  },
                ],
              })(<Input.TextArea />)}
            </Form.Item>
            <Form.Item label="Dead Weight (Kg)">
              {getFieldDecorator("deadweight", {
                rules: [
                  {
                    required: true,
                    message: "Please input the dead weight for this shipment!",
                  },
                ],
              })(<Input min="0" type="number" />)}
            </Form.Item>
            <Row gutter={15}>
              <Col xs={8}>
                <Form.Item label="Height (cm)">
                  {getFieldDecorator("dimensions.h", {
                    rules: [
                      {
                        required: true,
                        message: "Please input the shipment height!",
                      },
                    ],
                  })(<Input min="0" type="number" />)}
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item label="Width (cm)">
                  {getFieldDecorator("dimensions.w", {
                    rules: [
                      {
                        required: true,
                        message: "Please input the shipment width!",
                      },
                    ],
                  })(<Input min="0" type="number" />)}
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item label="Depth (cm)">
                  {getFieldDecorator("dimensions.d", {
                    rules: [
                      {
                        required: true,
                        message: "Please input the shipment depth!",
                      },
                    ],
                  })(<Input min="0" type="number" />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      );
    }
  }
);

class MasterShipments extends Component {
  state = {
    orders: [],
    selected: [],
    loading: true,
    error: false,
    errorMsg: "",
    filters: {
      search: "",
    },
    paging: {
      page: 1,
      per_page_count: 10,
      total: 0,
    },
    createShipmentModal: {
      visible: false,
      saving: false,
      data: {},
    },
  };

  fetchUsers() {
    axios({
      url: "/orders/get_master_shipments",
      method: "post",
      data: {
        page: this.state.paging.page,
        per_page_count: this.state.paging.per_page_count,
        filters: this.state.filters,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            orders: res.data.orders,
            paging: { ...this.state.paging, total: res.data.total },
            loading: false,
          });
        } else {
          this.setState({
            error: true,
            errorMsg: res.data.msg,
            loading: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.fetchUsers();
  }

  handlePageChange = (pagination) => {
    this.setState(
      {
        paging: { ...this.state.paging, page: pagination.current },
      },
      () => this.fetchUsers()
    );
  };

  changeFilter(k, v) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [k]: v,
        },
      },
      () => this.goToPage(1)
    );
  }

  goToPage(page) {
    this.setState(
      {
        loading: true,
        paging: {
          ...this.state.paging,
          page,
        },
      },
      () => this.fetchUsers()
    );
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  toggleShipmentModal(data = {}) {
    this.setState((state) => ({
      createShipmentModal: {
        ...state.createShipmentModal,
        visible: !state.createShipmentModal.visible,
        saving: false,
        data,
      },
    }));
  }

  saveShipment = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (
        this.state.createShipmentModal.data &&
        this.state.createShipmentModal.data._id &&
        this.state.createShipmentModal.data._id["$oid"]
      ) {
        values["edit"] = this.state.createShipmentModal.data._id["$oid"];
      }
      this.setState(
        (state) => ({
          createShipmentModal: {
            ...state.createShipmentModal,
            saving: true,
          },
        }),
        () => {
          axios({
            url: "/orders/create_master_shipment",
            method: "post",
            data: values,
          })
            .then((res) => {
              if (res.data.success === 1) {
                message.success("Shipment Created Successfully!");
                this.toggleShipmentModal();
                this.fetchUsers();
              } else {
                message.error(res.data.msg);
              }
            })
            .catch((e) => {
              message.error(e.message);
            });
        }
      );
    });
  };

  sendEmail = () => {
    confirm({
      title: "Send Email",
      content:
        "Are you sure you want to send email for master shipment? You cannot edit the shipments once the email is sent!",
      onOk: () => {
        const hide = message.loading("Sending Email", 0);
        axios({
          url: "/orders/master_shipment_email",
          method: "post",
          data: {
            ids: this.state.selected,
          },
        })
          .then((res) => {
            hide();
            return res;
          })
          .then((res) => {
            if (res.data.success === 1) {
              message.success("Email Sent Successfully!");
              this.fetchUsers();
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            hide();
            message.error(e.message);
          });
      },
    });
  };

  render() {
    const PageWrapper = (children) => (
      <div className="store-users">
        <Row>
          <h2 style={{ marginBottom: 30 }}>Master Shipments</h2>
        </Row>
        <Row>
          <h2>
            <Input.Search
              style={{ width: 400 }}
              placeholder="Enter Keywords"
              enterButton="Search"
              onSearch={(value) => this.changeFilter("search", value)}
            />
            <span style={{ float: "right" }}>
              {this.state.selected.length > 0 && (
                <>
                  <Button type="primary" onClick={() => this.sendEmail()}>
                    Send Email
                  </Button>{" "}
                </>
              )}
              <Button type="primary" onClick={() => this.toggleShipmentModal()}>
                Create Shipment
              </Button>
            </span>
          </h2>
        </Row>
        {children}
        <CreateMasterShipmentForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.createShipmentModal.visible}
          onCancel={() => this.toggleShipmentModal()}
          saving={this.state.createShipmentModal.saving}
          onCreate={this.saveShipment}
          data={this.state.createShipmentModal.data}
        />
      </div>
    );

    if (this.state.loading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={this.state.errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    let columns = [
      {
        title: "Master Shipment Id",
        dataIndex: "master_id",
      },
      {
        title: "AWBs",
        dataIndex: "awbs",
        render: (awbs) => awbs.join(", "),
      },
      {
        title: "Created On",
        dataIndex: "created_on",
        render: (date) => moment(date).format("DD MMM YYYY"),
      },
      {
        title: "Dead Weight (KG)",
        dataIndex: "deadweight",
      },
      {
        title: "Dimensions (H * W * D) (cm)",
        dataIndex: "dimensions",
        render: (dims) => `${dims.h} * ${dims.w} * ${dims.d}`,
      },
      {
        title: "Actions",
        dataIndex: "_id[$id]",
        render: (id, row) =>
          row.email_sent !== 1 && (
            <a href="#!" onClick={() => this.toggleShipmentModal(row)}>
              <Icon type="edit" /> Edit
            </a>
          ),
      },
    ];

    return PageWrapper(
      <Table
        className="sourcing-table"
        rowKey={(o) => o._id["$oid"]}
        pagination={{
          total: this.state.paging.total,
          pageSize: this.state.paging.per_page_count,
          current: this.state.paging.page,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={this.state.orders.filter((o) => o.quantity !== 0)}
        onChange={(paging) => this.goToPage(paging.current)}
        style={{ paddingBottom: 5 }}
        rowSelection={{
          onChange: (selectedRowKeys) => {
            this.setState({
              selected: [...selectedRowKeys],
            });
          },
          getCheckboxProps: (record) => ({
            disabled: record.email_sent === 1,
          }),
        }}
      />
    );
  }
}

export default MasterShipments;
