import React, { Component } from "react";
import {
  Alert,
  Skeleton,
  Row,
  Icon,
  Table,
  message,
  Input,
  DatePicker,
} from "antd";
import axios from "axios";
import "./index.scss";
import moment from "moment";

const { RangePicker } = DatePicker;

class VerifiedSourcing extends Component {
  state = {
    orders: [],
    selected: [],
    loading: true,
    error: false,
    errorMsg: "",
    filters: {
      search: "",
      startDate: moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
    paging: {
      page: 1,
      per_page_count: 10,
      total: 0,
    },
  };

  fetchUsers() {
    axios({
      url: "/orders/get_verified_sourcing_list",
      method: "post",
      data: {
        page: this.state.paging.page,
        per_page_count: this.state.paging.per_page_count,
        filters: this.state.filters,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            orders: res.data.orders,
            paging: { ...this.state.paging, total: res.data.total },
            loading: false,
          });
        } else {
          this.setState({
            error: true,
            errorMsg: res.data.msg,
            loading: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.fetchUsers();
  }

  handlePageChange = (pagination) => {
    this.setState(
      {
        loading: true,
        paging: { ...this.state.paging, page: pagination.current },
      },
      () => this.fetchUsers()
    );
  };

  setQuantity = (id, quantity) => {
    this.setState({
      orders: this.state.orders.map((o) => {
        if (o._id["$oid"] === id) {
          return {
            ...o,
            quantity,
          };
        } else {
          return o;
        }
      }),
    });
  };

  verifyQuantity = (id) => {
    const s = this.state.orders.find((o) => o._id["$oid"] === id);
    axios({
      url: "/orders/verify_sourcing_list",
      method: "post",
      data: {
        id,
        quantity: s.quantity,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success("Verified Successfully!");
          this.fetchUsers();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  changeFilter(filters) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filters,
        },
      },
      () => this.goToPage(1)
    );
  }

  goToPage(page) {
    this.setState(
      {
        loading: true,
        paging: {
          ...this.state.paging,
          page,
        },
      },
      () => this.fetchUsers()
    );
  }
  render() {
    const PageWrapper = (children) => (
      <div className="store-users">
        <Row>
          <h2 style={{ marginBottom: 30 }}>Verified Items List</h2>
        </Row>
        <Row>
          <h2>
            <a
              href="/orders/sourcing_csv?verified=true"
              target="_blank"
              rel="noreferrer noopener"
              className="ant-btn ant-btn-primary"
            >
              <Icon type="download" /> Download as CSV
            </a>
            <span style={{ float: "right" }}>
              <RangePicker
                format="YYYY-MM-DD"
                ranges={{
                  Today: [moment(), moment()],
                  "This Week": [
                    moment().startOf("week"),
                    moment().endOf("week"),
                  ],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                style={{ marginRight: 15, verticalAlign: "middle" }}
                value={[
                  moment(this.state.filters.startDate).isValid()
                    ? moment(this.state.filters.startDate)
                    : null,
                  moment(this.state.filters.endDate).isValid()
                    ? moment(this.state.filters.endDate)
                    : null,
                ]}
                onChange={(_, dates) =>
                  this.changeFilter({ startDate: dates[0], endDate: dates[1] })
                }
              />
              <Input.Search
                style={{ width: 400, verticalAlign: "middle" }}
                placeholder="Enter Keywords"
                enterButton="Search"
                onSearch={(value) => this.changeFilter({ search: value })}
              />
            </span>
          </h2>
        </Row>
        {children}
      </div>
    );

    if (this.state.loading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={this.state.errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    let columns = [
      {
        title: "Product Details",
        dataIndex: null,
        render: (_, r) => (
          <div style={{ display: "flex", alignItems: "ceneter" }}>
            <div>
              <img
                src={r.img}
                alt={r.name}
                style={{ width: "100%", maxWidth: "55px", marginRight: 20 }}
              />
            </div>
            <div className="blue-text" style={{ paddingLeft: 15 }}>
              {r.name}
            </div>
          </div>
        ),
      },
      {
        title: "Product Specs",
        width: "150px",
        dataIndex: "allspecs",
      },
      {
        title: "Product SKU",
        dataIndex: "vendorsku",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
      },
      {
        title: "Verified By",
        dataIndex: "verified_by",
      },
      {
        title: "Verified On",
        dataIndex: "date",
        render: (text) => moment(text).format("DD MMM, YYYY"),
      },
    ];

    return PageWrapper(
      <Table
        className="sourcing-table"
        rowKey={(o) => o._id["$oid"]}
        pagination={{
          total: this.state.paging.total,
          pageSize: this.state.paging.per_page_count,
          current: this.state.paging.page,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        columns={columns}
        scroll={{ x: 1000 }}
        dataSource={this.state.orders}
        onChange={(paging) => this.goToPage(paging.current)}
        style={{ paddingBottom: 5 }}
      />
    );
  }
}

export default VerifiedSourcing;
