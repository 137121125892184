import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../../../store/actions/authActions";
import { Layout, Menu, Icon } from "antd";
import "./Sidebar.scss";
import { ReactComponent as ShipmentIcon } from "../../../assets/images/icons/shipmentsIcon.svg";
import axios from "axios";
const { Sider } = Layout;
const SubMenu = Menu.SubMenu;

class Sidebar extends React.Component {
  state = {
    counts: {
      product_catalog: 0,
      open_orders: 0,
      all_orders: 0,
      shipments: 0,
    },
    access: {
      all: false,
      product_catalog: false,
      sync_products: false,
      sourcing: false,
      orders: false,
      shipments: false,
    },
    activeKey: "",
  };

  fetchSidebarCounts = () => {
    axios({
      url: "/user/get_sidebar_counts",
      method: "post",
      data: {
        storeid: this.props.match.params.storeid,
      },
    })
      .then((res) => {
        let access = {};
        res.data.access.forEach((a) => (access[a] = true));
        this.setState({
          counts: res.data.counts,
          access,
          activeKey: this.props.history.location.pathname,
        });
      })
      .catch(() => {});
  };

  componentDidMount() {
    this.fetchSidebarCounts();
    this.unlisten = this.props.history.listen((location) => {
      this.fetchSidebarCounts();
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const props = this.props;
    return (
      <Sider
        className={`sidebar ${
          props.sidebarOpen ? "sidebar-open" : "sidebar-closed"
        }`}
        width={256}
        style={{ background: "rgb(247, 247, 252)" }}
        breakpoint="md"
        collapsed={!props.sidebarOpen}
        collapsedWidth={0}
      >
        <Menu
          mode="inline"
          selectedKeys={[this.state.activeKey]}
          openKeys={["orders", "sourcing"]}
          style={{ height: "auto", borderRight: 0 }}
        >
          {(this.state.access.all || this.state.access.product_catalog) && (
            <Menu.Item key="/product-catalog">
              <Link to={`/product-catalog`}>
                <Icon type="book" />
                Product Catalog
                <span style={{ float: "right", marginRight: 18 }}>
                  {this.state.counts.product_catalog}
                </span>
              </Link>
            </Menu.Item>
          )}

          {(this.state.access.all || this.state.access.sync_products) && (
            <Menu.Item key="/sync-products">
              <Link to={`/sync-products`}>
                <Icon type="sync" />
                Sync Products
              </Link>
            </Menu.Item>
          )}

          {/* {(this.state.access.all || this.state.access.sourcing) && (
            <SubMenu
              key="sourcing"
              onTitleClick={() => props.history.push(`/sourcing`)}
              title={
                <div>
                  <Icon type="file-text" />
                  <span>Sourcing</span>
                </div>
              }
            >
              <Menu.Item key="/sourcing">
                <Link to="/sourcing">Item List</Link>
              </Menu.Item>
              <Menu.Item key="/sourcing/verified">
                <Link to="/sourcing/verified">Verified Items List</Link>
              </Menu.Item>
            </SubMenu>
          )} */}

          {(this.state.access.all || this.state.access.orders) && (
            <SubMenu
              key="orders"
              onTitleClick={() => props.history.push(`/open-orders`)}
              title={
                <div>
                  <Icon type="shopping-cart" />
                  <span>Orders</span>
                  <span className="order-count">
                    {this.state.counts.all_orders}
                  </span>
                </div>
              }
            >
              <Menu.Item key="/open-orders">
                <Link to="/open-orders">
                  Open Orders
                  <span style={{ float: "right", marginRight: 18 }}>
                    {this.state.counts.open_orders}
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item key="/orders">
                <Link to="/orders">
                  All Orders
                  <span style={{ float: "right", marginRight: 18 }}>
                    {this.state.counts.all_orders}
                  </span>
                </Link>
              </Menu.Item>
            </SubMenu>
          )}

          {(this.state.access.all || this.state.access.shipments) && (
            <Menu.Item key="/shipments">
              <Link to="/shipments">
                <Icon component={ShipmentIcon} /> Shipments
                <span style={{ float: "right", marginRight: 18 }}>
                  {this.state.counts.shipments}
                </span>
              </Link>
            </Menu.Item>
          )}
          {/* {(this.state.access.all || this.state.access.shipments) && (
            <Menu.Item key="/master-shipments">
              <Link to="/master-shipments">
                <Icon component={ShipmentIcon} /> Master Shipments
              </Link>
            </Menu.Item>
          )}
          {this.state.access.all && (
            <Menu.Item key="/new-custom-product">
              <Link to="/new-custom-product">
                <Icon component={ShipmentIcon} />
                New Custom Product
              </Link>
            </Menu.Item>
          )} */}
        </Menu>

        <Menu
          className="show-on-small-screen"
          mode="inline"
          defaultSelectedKeys={["help"]}
          defaultOpenKeys={["help"]}
          style={{ borderRight: "0px", position: "sticky", top: "100%" }}
        >
          <SubMenu
            key="user"
            title={
              <p style={{ lineHeight: "14px", margin: "6px 0" }}>
                {props.user.user_fname}
                <br />
                {props.store && props.store.store_name
                  ? props.store.store_name
                  : props.user.user_fname.split(" ")[0] + "'s Store"}
              </p>
            }
          >
            <Menu.Item key="user_logout">
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  props.signOut();
                }}
              >
                <Icon type="poweroff" /> Logout
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.auth.auth.store,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
