import React, { Component } from 'react';
import { Icon, Input, Button, Row, Col, Divider, Alert, Skeleton, message } from 'antd';
import axios from 'axios';

import './index.scss';

class ProfileForm extends Component {
  state = {
    profile: {
      user_fname: '',
      user_email: '',
      password: '',
      confirm_password: ''
    },
    errors: {
      user_fname: '',
      confirm_password: '',
    },
    formDirty: false,
    hasError: false,
    loading: true,
  };

  componentDidMount() {
    axios({
      url: '/user/get_profile',
      method: 'post',
    }).then((res) => {
      if (res.data.success === 1) {
        this.setState({
          profile: { ...res.data.profile, password: '', confirm_password: '' },
          loading: false,
        })
      } else {
        this.setState({
          error: true,
          errorMsg: res.data.msg,
          loading: false
        })
      }
    }).catch((e) => {
      this.setState({
        error: true,
        errorMsg: e.message,
        loading: false
      })
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.validateProfile(() => {
      if (!this.state.formDirty) {
        axios({
          url: '/user/update_profile',
          method: 'post',
          data: { ...this.state.profile, user_email: undefined }
        }).then((res) => {
          if (res.data.success === 1) {
            message.success("Updated Successfully");
            this.setState({
              profile: { ...this.state.profile, password: '', confirm_password: '' }
            })
          } else {
            message.error(res.data.msg);
          }
        }).catch((e) => {
          message.error(e.message);
        })
      }
    })
  }

  updateField(field, value) {
    this.setState({
      profile: { ...this.state.profile, [field]: value }
    }, () => this.validateProfile())
  }

  validateProfile(callback) {
    const profile = this.state.profile;
    let errors = {};
    if (profile.user_fname === "") {
      errors.user_fname = "Please enter your full name";
    }
    if (profile.password !== '' || profile.confirm_password !== '') {
      if (profile.password !== profile.confirm_password) {
        errors.confirm_password = "Passwords don't match";
      }
    }
    let formDirty = false;
    if (Object.keys(errors).length) {
      formDirty = true;
    }
    this.setState({
      errors,
      formDirty
    }, () => { callback && callback() })
  }

  render() {

    if (this.state.loading) {
      return (
        <Skeleton active />
      )
    }

    if (!this.state.loading && this.state.error) {
      return (
        <Row>
          <Alert
            message="There was an error"
            description={this.state.errorMsg}
            type="error"
          />
        </Row>
      )
    }
    return (
      <form layout="horizontal" onSubmit={(e) => this.handleSubmit(e)} className="profile-form" novalidate>
        <Row>
          <Col xs={24} md={12} className="column">
            <h3><b>Profile Settings</b></h3>
          </Col>

          <Col xs={24} md={12} className="button-container column">
            <Button
              type="primary"
              htmlType="submit"
              disabled={this.state.formDirty}
            >Save Profile</Button>
          </Col>
        </Row>

        <Divider />

        <Row style={{ marginBottom: "1.5em" }}>
          <Col xs={24} md={12} className="column">
            <div className={`ant-form-item-control ${this.state.errors.user_fname ? 'has-error' : ''}`}>
              <p style={{ margin: 0 }}><b>Name</b></p>
              <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} style={{ width: "100%", maxWidth: "400px" }} defaultValue={this.state.profile.user_fname} onChange={(e) => this.updateField('user_fname', e.target.value)} />
              {this.state.errors.user_fname && <div class="ant-form-explain">{this.state.errors.user_fname}</div>}
            </div>
          </Col>

          <Col xs={24} md={12} className="column">
            <div className="ant-form-item-control">
              <p style={{ margin: 0 }}><b>Email</b></p>
              <Input type="email" value={this.state.profile.user_email} disabled={true} prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />} style={{ width: "100%", maxWidth: "400px" }} />
            </div>
          </Col>
        </Row>

        <Divider />

        <Row style={{ marginBottom: "1.5em" }}>
          <Col xs={24} md={12} className="column">
            <div className={`ant-form-item-control ${this.state.errors.confirm_password ? 'has-error' : ''}`}>
              <p style={{ margin: 0 }}><b>Password</b></p>
              <Input type="password" prefix={<Icon type="key" style={{ color: 'rgba(0,0,0,.25)' }} />} value={this.state.profile.password} style={{ width: "100%", maxWidth: "400px" }} onChange={(e) => this.updateField('password', e.target.value)} />
              {this.state.errors.confirm_password && <div class="ant-form-explain">{this.state.errors.confirm_password}</div>}
            </div>
          </Col>


          <Col xs={24} md={12} className="column">
            <div className={`ant-form-item-control ${this.state.errors.confirm_password ? 'has-error' : ''}`}>
              <p style={{ margin: 0 }}><b>Confirm Password</b></p>
              <Input type="password" value={this.state.profile.confirm_password} onBlur={this.handleConfirmBlur} prefix={<Icon type="key" style={{ color: 'rgba(0,0,0,.25)' }} />} style={{ width: "100%", maxWidth: "400px" }} onChange={(e) => this.updateField('confirm_password', e.target.value)} />
              {this.state.errors.confirm_password && <div class="ant-form-explain">{this.state.errors.confirm_password}</div>}
            </div>
          </Col>
        </Row>
      </form >
    );
  }
}

export default ProfileForm;