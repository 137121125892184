import React from "react";
import { Row, Col, Card, Icon, Tooltip, message } from "antd";
import { ReactComponent as ShipmentStatusIcon } from "../../../assets/images/icons/shopping-cart.svg";
import axios from "axios";
import moment from "moment";
import "./index.scss";

class OrderCard extends React.Component {
  state = {
    order: this.props.order,
    activeTab: this.props.pageType === "shipments" ? "shipments" : "lineItems",
    customerTab: {
      editing: false,
      saving: false,
    },
    selectedLineItems: [],
    errors: {},
    shipmentModal: {
      visible: false,
      saving: false,
      awb: "",
      carrier: "",
    },
    itemsVisible: true,
  };

  setActiveTab = (activeTab) => {
    this.setState({
      activeTab,
    });
  };

  lineItemSelectedChange(id) {
    let selectedLineItems = [...this.state.selectedLineItems];
    const findIndex = selectedLineItems.findIndex((li) => li === id);
    if (findIndex === -1) {
      selectedLineItems.push(id);
    } else {
      selectedLineItems = [
        ...selectedLineItems.slice(0, findIndex),
        ...selectedLineItems.slice(findIndex + 1),
      ];
    }
    this.setState({
      selectedLineItems,
    });
  }

  onAddNote(note) {
    this.setState({
      order: {
        ...this.state.order,
        vendor_notes: [
          note,
          ...(this.state.order.vendor_notes
            ? this.state.order.vendor_notes
            : []),
        ],
      },
    });
  }

  toggleLineItemEditable(id) {
    const that = this;
    this.setState({
      order: {
        ...this.state.order,
        vendor_lineitems: this.state.order.vendor_lineitems.map((li) => {
          if (li.lineitem_id === id) {
            if (
              that.state.selectedLineItems.findIndex((li) => li === id) ===
                -1 &&
              li.editable
            ) {
              that.lineItemSelectedChange(id);
            }
            return {
              ...li,
              editable: !li.editable,
            };
          } else {
            return li;
          }
        }),
      },
    });
  }

  changeLineItemQty(id, qty) {
    this.setState({
      order: {
        ...this.state.order,
        vendor_lineitems: this.state.order.vendor_lineitems.map((li) => {
          if (li.lineitem_id === id) {
            return {
              ...li,
              quantity: qty,
            };
          } else {
            return li;
          }
        }),
      },
    });
  }

  selectAllNone(checked) {
    let selectedLineItems = [];
    if (checked) {
      selectedLineItems = this.state.order.vendor_lineitems
        .filter((li) => li.item_vforderstatus === "open")
        .map((li) => li.lineitem_id);
    }
    this.setState({
      selectedLineItems,
    });
  }

  selectAllAndConfirm(key, id) {
    this.selectAllNone(true);
    this.props.toggleConfirmationModal(key, id);
  }

  updateShipment(k, v) {
    this.setState({
      shipmentModal: {
        ...this.state.shipmentModal,
        [k]: v,
      },
    });
  }

  toggleShipmentModal = () => {
    this.setState({
      shipmentModal: {
        ...this.state.shipmentModal,
        visible: !this.state.shipmentModal.visible,
      },
    });
  };

  createShipment = () => {
    this.setState(
      {
        shipmentModal: {
          ...this.state.shipmentModal,
          saving: true,
        },
      },
      () => {
        axios({
          url: "/orders/create_shipment",
          method: "post",
          data: {
            carrier: this.state.shipmentModal.carrier,
            awb: this.state.shipmentModal.awb,
            orderid: this.state.order._id["$oid"],
            items: this.state.order.vendor_lineitems.filter(
              (i) => this.state.selectedLineItems.indexOf(i.lineitem_id) !== -1
            ),
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              message.success("Shipment Created Successfully!");
              this.setState(
                {
                  shipmentModal: {
                    ...this.state.shipmentModal,
                    awb: res.data.awb,
                  },
                },
                () => this.props.reloadList()
              );
            } else {
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            message.error(e.message);
          });
      }
    );
  };

  toggleShipmentVisible() {
    this.setState({
      itemsVisible: !this.state.itemsVisible,
    });
  }

  render() {
    const { shipmentStatusColors, shipmentStatusText } = this.props;

    const order = this.state.order;

    return (
      <Card className={`order-card`}>
        <Row className="header-row">
          <Col xs={8} style={{ minHeight: 40, paddingTop: 5 }}>
            <h3>
              <span className="mr-3" style={{ fontSize: 16, fontWeight: 500 }}>
                Shipment Id
              </span>
              <span className="blue-text mr-3" style={{ fontWeight: 500 }}>
                {order.shipment_vfid}
              </span>
              <Tooltip title="Shipment Date">
                <span style={{ color: "#949494" }}>
                  {moment(order.shipment_date).format("DD MMM, YYYY")}
                </span>
              </Tooltip>
            </h3>
          </Col>
          <Col
            xs={8}
            style={{ minHeight: 40, paddingTop: 5, textAlign: "center" }}
          >
            <h3>
              <span className="mr-3" style={{ fontSize: 16, fontWeight: 500 }}>
                Order
              </span>
              <span className="blue-text mr-3" style={{ fontWeight: 500 }}>
                #{order.order_vfid}
              </span>
              <Tooltip title="Order Date">
                <span style={{ color: "#949494" }}>
                  {moment(order.order_date).format("DD MMM, YYYY")}
                </span>
              </Tooltip>
            </h3>
          </Col>
          <Col xs={8} style={{ textAlign: "right" }}>
            <span style={{ fontSize: 16, fontWeight: 500 }}>
              <b>Total Item Qty: </b>
              <span style={{ color: "#676666" }}>
                {order.items.reduce((acc, c) => acc + parseInt(c.quantity), 0)}
              </span>
            </span>
          </Col>
        </Row>

        <Row className="tabs-row">
          <Col xs={24}>
            <ul>
              <li
                className={this.state.activeTab === "shipments" ? "active" : ""}
                onClick={() => this.setActiveTab("shipments")}
              >
                Shipments
              </li>
            </ul>
          </Col>
        </Row>

        <div className={`tab-container`}>
          <div className="shipments-tab">
            <div className="shipment" key={`${order.carrier}_${order.awb}`}>
              <div className="shipment-header">
                <Row gutter={0}>
                  <Col xs={7} style={{ textAlign: "center" }}>
                    <span>Tracking Number</span>{" "}
                    <span className="blue-text">{order.awb}</span>
                  </Col>
                  <Col xs={5} style={{ textAlign: "center" }}>
                    <span style={{ color: shipmentStatusColors[order.status] }}>
                      <Icon component={ShipmentStatusIcon} />{" "}
                      {shipmentStatusText[order.status]}
                    </span>
                  </Col>
                  <Col xs={7} style={{ textAlign: "center" }}>
                    <span>
                      {order.awb ? (
                        <a
                          href={`/orders/print_package_slip/${order.awb}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Print Packaging Slip
                        </a>
                      ) : null}
                    </span>
                  </Col>
                  <Col xs={5} style={{ textAlign: "right" }}>
                    <span>
                      <a
                        href="#!"
                        onClick={() => this.toggleShipmentVisible(order.awb)}
                      >
                        {this.state.itemsVisible ? "Hide" : "Show"} Products{" "}
                        <Icon type={this.state.itemsVisible ? "up" : "down"} />
                      </a>
                    </span>
                  </Col>
                </Row>
              </div>
              <div
                className={`shipment-items ${
                  this.state.itemsVisible ? "" : "hidden"
                }`}
              >
                {order.items.map((li) => (
                  <Row className="shipment-item" key={li.lineitem_id}>
                    <Col xs={10}>
                      <Row className="shipment-item-product-detail">
                        <Col xs={4}>
                          <span
                            className="shipment-item-img"
                            style={{ background: `url(${li.img})` }}
                          />
                        </Col>
                        <Col xs={20}>
                          <p className="blue-text shipment-item-name">
                            {li.aliname}{" "}
                          </p>
                          <p className="shipment-item-sku">
                            <b>SKU: </b> {li.vendorsku}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={5} style={{ textAlign: "center" }}>
                      <Tooltip title="Print MRP Label">
                        <a
                          href={`/products/print_mrp_label?sku=${
                            li.vendorsku
                          }&name=${li.name}&mrp=${li.price.amount}&pid=${
                            li.product_mongoid
                          }&variant=${li.variant_shopifyid}`}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <Icon type="barcode" />
                        </a>
                      </Tooltip>
                    </Col>
                    <Col xs={5} style={{ textAlign: "center" }}>
                      <span className="mr-3">{li.quantity}</span>
                      <span className="mr-3">X</span>
                      <span className="mr-3">${li.item_mrpusd}</span>
                    </Col>
                    <Col xs={4} style={{ textAlign: "center" }}>
                      ${li.quantity * li.item_mrpusd}
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default OrderCard;
