import React, { Component } from 'react';
import { Button, Input, Modal } from 'antd';
import './index.scss';

class AddNoteModal extends Component {
	state = {
		note: null
	};

	handleChange = (field, value) => {
		this.setState({
			...this.state,
			[field]: value
		});
	};

	resetModal = () => {
		this.setState({
			note: null
		});
	};

	render() {
		const { visible, error, onCancel, onConfirm } = this.props;
		const { note } = this.state;

		if (error) {
			console.log(error);
		}
		return (
			<Modal
				className="orders-add-note-modal"
				title="Add Note"
				visible={visible}
				onCancel={onCancel}
				closable={!this.props.saving}
				afterClose={this.resetModal}
				footer={[
					<Button key="back" onClick={onCancel}>Cancel</Button>,
					<Button key="submit" type="primary" onClick={() => onConfirm(this.state)} loading={this.props.saving}>Add</Button>,
				]}
			>
				<div>
					<Input.TextArea
						value={note}
						onChange={(e) => this.handleChange("note", e.target.value)}
						autosize={{ minRows: 6 }}
					/>
				</div>
			</Modal>
		);
	}
}

export default AddNoteModal;