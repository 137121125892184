import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { ReactComponent as NotFoundIcon } from '../../assets/images/icons/404.svg';
import logo from '../../assets/images/logos/logo_white.svg';
const { Content, Header } = Layout;

const NotFound404 = () => {
  return (
    <Layout style={{ height: "100%" }}>
      <Header className="navbar">
        <div className="logo">
          <Link to='/'><img src={logo} alt="vFulfill" /></Link>
        </div>
      </Header>

      <Layout style={{ height: "100%" }}>
        <Content style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div className="404" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <NotFoundIcon />
            <div style={{ paddingTop: "30px", textAlign: "center" }}>
              <h1 style={{ marginBottom: 0 }}>Oops... Looks like you got lost</h1>
              <h3><Link to="/" style={{ color: "rgba(0, 0, 0, 0.45)" }}>Click here to get back home</Link></h3>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout >
  );
}

export default NotFound404;