import React from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Icon,
  Checkbox,
  Tooltip,
  InputNumber,
  message,
  Avatar,
  Modal,
  Alert,
} from "antd";
import { ReactComponent as ShipmentStatusIcon } from "../../../assets/images/icons/shopping-cart.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment-timezone";
import "./index.scss";
import ShipmentConfirmationModal from "../ShipmentConfirmationModal";
import { GetAliURL } from "../../../Helpers/URL";

class OrderCard extends React.Component {
  state = {
    order: this.props.order,
    activeTab: this.props.pageType === "shipments" ? "shipments" : "lineItems",
    customerTab: {
      editing: false,
      saving: false,
    },
    selectedLineItems: [],
    errors: {},
    shipmentModal: {
      visible: false,
      printModalVisible: false,
      saving: false,
      awb: "",
      carrier: "",
      weight: 0,
    },
    shipmentsVisible: {},
    tatDelayed: 0,
  };

  setActiveTab = (activeTab) => {
    this.setState({
      activeTab,
    });
  };

  lineItemSelectedChange(id) {
    let selectedLineItems = [...this.state.selectedLineItems];
    const findIndex = selectedLineItems.findIndex((li) => li === id);
    if (findIndex === -1) {
      selectedLineItems.push(id);
    } else {
      selectedLineItems = [
        ...selectedLineItems.slice(0, findIndex),
        ...selectedLineItems.slice(findIndex + 1),
      ];
    }
    this.setState({
      selectedLineItems,
    });
  }

  onAddNote(note) {
    this.setState({
      order: {
        ...this.state.order,
        vendor_notes: [
          note,
          ...(this.state.order.vendor_notes
            ? this.state.order.vendor_notes
            : []),
        ],
      },
    });
  }

  toggleLineItemEditable(id) {
    const that = this;
    this.setState({
      order: {
        ...this.state.order,
        vendor_lineitems: this.state.order.vendor_lineitems.map((li) => {
          if (li.lineitem_id === id) {
            if (
              that.state.selectedLineItems.findIndex((li) => li === id) ===
                -1 &&
              li.editable
            ) {
              that.lineItemSelectedChange(id);
            }
            return {
              ...li,
              editable: !li.editable,
            };
          } else {
            return li;
          }
        }),
      },
    });
  }

  tatFailedItems() {
    return this.state.order.vendor_lineitems.filter((item) => {
      const od = moment(item.confirmation_date);
      const diff = moment().diff(od, "days");

      return diff > 3;
    });
  }

  changeLineItemQty(id, qty) {
    this.setState({
      order: {
        ...this.state.order,
        vendor_lineitems: this.state.order.vendor_lineitems.map((li) => {
          if (li.lineitem_id === id) {
            return {
              ...li,
              quantity: qty,
            };
          } else {
            return li;
          }
        }),
      },
    });
  }

  selectAllNone(checked) {
    let selectedLineItems = [];
    if (checked) {
      selectedLineItems = this.state.order.vendor_lineitems
        .filter((li) => li.item_vforderstatus === "open")
        .map((li) => li.lineitem_id);
    }
    this.setState({
      selectedLineItems,
    });
  }

  selectAllAndConfirm(key, id) {
    this.selectAllNone(true);
    this.props.toggleConfirmationModal(key, id);
  }

  updateShipment(k, v) {
    this.setState({
      shipmentModal: {
        ...this.state.shipmentModal,
        [k]: v,
      },
    });
  }

  toggleShipmentModal = () => {
    if (
      !this.state.shipmentModal.visible &&
      this.state.selectedLineItems.length === 0
    ) {
      let lineItems = this.state.order.vendor_lineitems;
      if (this.props.pageType === "openorders") {
        lineItems = lineItems.filter(
          (li) => li.item_shipmentstatus === "processing"
        );
      }
      lineItems = lineItems.filter(
        (li) =>
          li.vendorsku &&
          li.item_shipmentstatus !== "shipped" &&
          li.item_shipmentstatus !== "manifested" &&
          li.item_vforderstatus !== "verifyorder"
      );
      this.setState({
        selectedLineItems: lineItems.map((li) => li.lineitem_id),
      });
    }
    this.setState({
      shipmentModal: {
        ...this.state.shipmentModal,
        visible: !this.state.shipmentModal.visible,
      },
    });
  };

  createShipment = () => {
    this.setState(
      {
        shipmentModal: {
          ...this.state.shipmentModal,
          saving: true,
        },
      },
      () => {
        axios({
          url: "/orders/create_shipment",
          method: "post",
          data: {
            carrier: this.state.shipmentModal.carrier,
            awb: this.state.shipmentModal.awb,
            weight: this.state.shipmentModal.weight,
            orderid: this.state.order._id["$oid"],
            items: this.state.order.vendor_lineitems.filter(
              (i) => this.state.selectedLineItems.indexOf(i.lineitem_id) !== -1
            ),
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              message.success("Shipment Created Successfully!");
              this.setState({
                shipmentModal: {
                  ...this.state.shipmentModal,
                  visible: false,
                  printModalVisible: true,
                  awb: res.data.awb,
                },
              });
            } else {
              this.setState({
                shipmentModal: {
                  ...this.state.shipmentModal,
                  saving: false,
                },
              });
              message.error(res.data.msg);
            }
          })
          .catch((e) => {
            this.setState({
              shipmentModal: {
                ...this.state.shipmentModal,
                saving: false,
              },
            });
            message.error(e.message);
          });
      }
    );
  };

  toggleShipmentsVisible(id) {
    this.setState({
      shipmentsVisible: {
        [id]: !this.state.shipmentsVisible[id],
      },
    });
  }

  render() {
    const failed_items = this.tatFailedItems();
    let od = moment();
    let totalDelay = 0;
    if (failed_items.length > 0) {
      od = moment(failed_items[0].confirmation_date);
      const result = moment().diff(od, "days");
      totalDelay = result - 3;
    }

    const { shipmentStatusColors, shipmentStatusText } = this.props;
    const order = this.state.order;
    moment.tz.setDefault("UTC");

    let createShipmentEnabled = false;
    (this.props.pageType === "openorders"
      ? order.vendor_lineitems.filter(
          (li) => li.item_shipmentstatus === "processing"
        )
      : order.vendor_lineitems
    ).forEach((li) => {
      if (
        li.item_shipmentstatus !== "shipped" &&
        li.item_shipmentstatus !== "manifested" &&
        li.vendorsku
      ) {
        createShipmentEnabled = true;
      }
    });

    console.log(createShipmentEnabled);

    return (
      <Card
        className={
          failed_items.length > 0 ? "order-card tatFailed" : "order-card"
        }
      >
        {totalDelay > 0 && (
          <Row style={{ padding: "15px 15px 0 15px" }}>
            <Alert
              message={`TAT has been delayed by ${totalDelay} days`}
              type="error"
            />
          </Row>
        )}
        <Row className="header-row">
          <Col xs={18} style={{ minHeight: 40, paddingTop: 5 }}>
            <h3>
              <span className="mr-3" style={{ fontSize: 16, fontWeight: 500 }}>
                vFulfill Order Id
              </span>
              <span className="blue-text mr-3" style={{ fontWeight: 500 }}>
                {order.order_vfid}
              </span>
              <Tooltip title="Order Date">
                <span style={{ color: "#949494" }}>
                  {moment(order.order_time).format("DD MMM, YYYY")}
                </span>
              </Tooltip>
            </h3>
          </Col>
          <Col xs={6} style={{ textAlign: "right" }}>
            <Button.Group>
              <Button
                className="add-note-btn"
                onClick={() =>
                  this.props.toggleNotesModal(order._id["$oid"], (note) =>
                    this.onAddNote(note)
                  )
                }
              >
                Add Note
              </Button>
              <Tooltip title="View Notes">
                <Button
                  className="view-notes-btn"
                  icon="file-text"
                  onClick={() => this.setActiveTab("vendornotes")}
                />
              </Tooltip>
            </Button.Group>
            <Button
              type="primary"
              onClick={this.toggleShipmentModal}
              style={{ marginLeft: 15 }}
              disabled={!createShipmentEnabled}
            >
              Create Shipment
            </Button>
          </Col>
        </Row>

        <Row className="tabs-row">
          <Col xs={24}>
            <ul>
              {this.props.pageType !== "shipments" && (
                <li
                  className={
                    this.state.activeTab === "lineItems" ? "active" : ""
                  }
                  onClick={() => this.setActiveTab("lineItems")}
                >
                  Order Items
                </li>
              )}
              <li
                className={this.state.activeTab === "shipments" ? "active" : ""}
                onClick={() => this.setActiveTab("shipments")}
              >
                Shipments
              </li>
              <li
                className={this.state.activeTab === "notes" ? "active" : ""}
                onClick={() => this.setActiveTab("notes")}
              >
                Shop Owner Notes{" "}
                <Avatar
                  style={{
                    backgroundColor: "#B5742B",
                    verticalAlign: "middle",
                    marginLeft: 10,
                  }}
                >
                  {order.order_notes ? order.order_notes.length : 0}
                </Avatar>
              </li>
              <li
                className={
                  this.state.activeTab === "vendornotes" ? "active" : ""
                }
                onClick={() => this.setActiveTab("vendornotes")}
              >
                Vendor User's Notes{" "}
                <Avatar
                  style={{
                    backgroundColor: "#B5742B",
                    verticalAlign: "middle",
                    marginLeft: 10,
                  }}
                >
                  {order.vendor_notes ? order.vendor_notes.length : 0}
                </Avatar>
              </li>
            </ul>
          </Col>
        </Row>

        <div
          className={`tab-container ${
            this.state.activeTab !== "lineItems" ? "hidden" : ""
          }`}
        >
          <div className="line-items-tab">
            {(this.props.pageType === "openorders"
              ? order.vendor_lineitems.filter(
                  (li) => li.item_shipmentstatus === "processing"
                )
              : order.vendor_lineitems
            ).map((li) => {
              return (
                <Row
                  className={`line-item ${
                    this.state.selectedLineItems.find(
                      (i) => i === li.lineitem_id
                    ) !== undefined
                      ? "selected"
                      : ""
                  }`}
                  key={li.lineitem_id}
                >
                  <Col xs={10}>
                    <Row className="line-item-product-detail">
                      <Col xs={2}>
                        {li.item_shipmentstatus !== "shipped" &&
                          li.item_shipmentstatus !== "manifested" &&
                          li.vendorsku && (
                            <Checkbox
                              disabled={li.item_vforderstatus === "verifyorder"}
                              checked={
                                this.state.selectedLineItems.find(
                                  (i) => i === li.lineitem_id
                                ) !== undefined
                              }
                              onChange={() =>
                                this.lineItemSelectedChange(li.lineitem_id)
                              }
                            />
                          )}
                      </Col>
                      <Col xs={4}>
                        <span
                          className="line-item-img"
                          style={{ background: `url(${li.img})` }}
                        />
                      </Col>
                      <Col xs={18}>
                        <p className="blue-text line-item-name">
                          <Tooltip title={li.aliname} placement="topLeft">
                            {li.aliname}
                          </Tooltip>
                        </p>
                        <p className="line-item-sku">
                          <b style={{ verticalAlign: "bottom" }}>SKU: </b>{" "}
                          {li.vfsku ? li.vfsku : "N.A."}
                        </p>
                        <p className="shipment-item-sku">
                          <b>Specs: </b>{" "}
                          {li.allspecs === "" ? "Default" : li.allspecs}
                        </p>
                        <p className="shipment-item-sku">
                          <a
                            href={GetAliURL(
                              `https://aliexpress.com/item/-/${
                                li.product_aliid
                              }.html`
                            )}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            View Product on AliExpress
                          </a>
                          {li.vendorsku && <span> | </span>}
                          {li.vendorsku && (
                            <a
                              href={`/products/print_mrp_label?sku=${
                                li.vfsku
                              }&name=${li.aliname}&mrp=${li.price.amount}&pid=${
                                li.product_mongoid
                              }&variant=${li.variant_shopifyid}`}
                              target="_blank"
                              rel="noreferrer noopener"
                              style={{ marginLeft: 5, display: "inline-block" }}
                            >
                              Print MRP Label
                            </a>
                          )}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={5} style={{ textAlign: "center", color: "#676666" }}>
                    Order Confirmation Date: <br />{" "}
                    {moment(li.confirmation_date)
                      .tz("Asia/Shanghai")
                      .format("DD MMM, YYYY | HH:mm")}
                  </Col>
                  <Col xs={5} style={{ paddingLeft: "4%" }}>
                    {li.editable ? (
                      <InputNumber
                        className="quantity-field"
                        min={1}
                        max={li.original_qty}
                        value={li.quantity}
                        onChange={(e) =>
                          this.changeLineItemQty(li.lineitem_id, e)
                        }
                      />
                    ) : (
                      <span className="mr-3">{li.quantity}</span>
                    )}
                    <span className="mr-3">X</span>
                    <Tooltip title="Vendor Price" className="mr-3">
                      $ {li.item_mrpusd}
                    </Tooltip>
                  </Col>
                  <Col
                    xs={4}
                    style={{
                      textAlign: "center",
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    {li.vendorprice ? (
                      li.item_shipmentstatus !== "manifested" ? (
                        <Button
                          className="edit-quantity-btn"
                          onClick={() =>
                            this.toggleLineItemEditable(li.lineitem_id)
                          }
                        >
                          {li.editable ? "Done" : "Edit Quantity"}
                        </Button>
                      ) : (
                        "Manifested"
                      )
                    ) : (
                      <Link to={`/sync-products/${li.product_aliid}`}>
                        Link Product
                      </Link>
                    )}
                  </Col>
                </Row>
              );
            })}
          </div>
        </div>

        <div
          className={`tab-container ${
            this.state.activeTab !== "shipments" ? "hidden" : ""
          }`}
        >
          <div className="shipments-tab">
            {order.order_shipments.length > 0 ? (
              order.order_shipments.map((s) => (
                <div className="shipment" key={`${s.carrier}_${s.awb}`}>
                  <div className="shipment-header">
                    <Row gutter={0}>
                      <Col xs={7} style={{ textAlign: "center" }}>
                        <span>Tracking Number</span>{" "}
                        <span className="blue-text">{s.awb}</span>
                      </Col>
                      <Col xs={5} style={{ textAlign: "center" }}>
                        <span style={{ color: shipmentStatusColors[s.status] }}>
                          <Icon component={ShipmentStatusIcon} />{" "}
                          {shipmentStatusText[s.status]}
                        </span>
                      </Col>
                      <Col xs={7} style={{ textAlign: "center" }}>
                        <span>
                          {s.awb ? (
                            <a
                              href={`/orders/print_package_slip/${s.awb}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Print Packaging Slip
                            </a>
                          ) : null}
                        </span>
                      </Col>
                      <Col xs={5} style={{ textAlign: "right" }}>
                        <span>
                          <a
                            href="#!"
                            onClick={() => this.toggleShipmentsVisible(s.awb)}
                          >
                            {this.state.shipmentsVisible[s.awb]
                              ? "Hide"
                              : "Show"}{" "}
                            Products{" "}
                            <Icon
                              type={
                                this.state.shipmentsVisible[s.awb]
                                  ? "up"
                                  : "down"
                              }
                            />
                          </a>
                        </span>
                      </Col>
                    </Row>
                  </div>
                  <div
                    className={`shipment-items ${
                      this.state.shipmentsVisible[s.awb] ? "" : "hidden"
                    }`}
                  >
                    {s.items.map((li) => (
                      <Row className="shipment-item" key={li.lineitem_id}>
                        <Col xs={10}>
                          <Row className="shipment-item-product-detail">
                            <Col xs={4}>
                              <span
                                className="shipment-item-img"
                                style={{ background: `url(${li.img})` }}
                              />
                            </Col>
                            <Col xs={20}>
                              <p className="blue-text shipment-item-name">
                                {li.title}{" "}
                              </p>
                              <p className="shipment-item-sku">
                                <b>SKU: </b> {li.vendorsku}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={5} style={{ textAlign: "center" }}>
                          <Tooltip title="Print Product SKU Code">
                            <a
                              href={`/products/print_sku?sku=${li.vendorsku}`}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              <Icon type="barcode" />
                            </a>
                          </Tooltip>
                        </Col>
                        <Col xs={5} style={{ textAlign: "center" }}>
                          <span className="mr-3">{li.quantity}</span>
                          <span className="mr-3">X</span>
                          <span className="mr-3">${li.item_mrpusd}</span>
                        </Col>
                        <Col xs={4} style={{ textAlign: "center" }}>
                          ${li.quantity * li.item_mrpusd}
                        </Col>
                      </Row>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <div className="no-shipment">
                <h3>No Shipments Found!</h3>
              </div>
            )}
          </div>
        </div>

        <div
          className={`tab-container ${
            this.state.activeTab !== "notes" ? "hidden" : ""
          }`}
        >
          <div className="notes-tab">
            {order.order_notes ? (
              order.order_notes.map((n) => (
                <Row className="note" key={n.date}>
                  <Col xs={13}>{n.note}</Col>
                  <Col xs={6}>
                    {moment(n.date).format("DD MMM, YYYY | HH:mm:ss")}
                  </Col>
                  <Col xs={5} style={{ textAlign: "right" }}>
                    by {n.addedbyname}
                  </Col>
                </Row>
              ))
            ) : (
              <h3 style={{ textAlign: "center" }}>No Notes Added!</h3>
            )}
          </div>
        </div>

        <div
          className={`tab-container ${
            this.state.activeTab !== "vendornotes" ? "hidden" : ""
          }`}
        >
          <div className="vendornotes-tab">
            {order.vendor_notes ? (
              order.vendor_notes.map((n) => (
                <Row className="note" key={n.date}>
                  <Col xs={13}>{n.note}</Col>
                  <Col xs={6}>
                    {moment(n.date).format("DD MMM, YYYY | HH:mm:ss")}
                  </Col>
                  <Col xs={5} style={{ textAlign: "right" }}>
                    by {n.addedbyname}
                  </Col>
                </Row>
              ))
            ) : (
              <h3 style={{ textAlign: "center" }}>No Notes Added!</h3>
            )}
          </div>
        </div>

        <ShipmentConfirmationModal
          visible={this.state.shipmentModal.visible}
          saving={this.state.shipmentModal.saving}
          awb={this.state.shipmentModal.awb}
          weight={this.state.shipmentModal.weight}
          onWeightChange={(val) =>
            this.setState({
              shipmentModal: { ...this.state.shipmentModal, weight: val },
            })
          }
          orders={{
            [this.state.order
              .order_vfid]: this.state.order.vendor_lineitems.filter(
              (li) =>
                this.state.selectedLineItems.indexOf(li.lineitem_id) !== -1
            ),
          }}
          currencies={this.props.currencies}
          id={this.state.order.order_vfid}
          onCancel={() => this.toggleShipmentModal()}
          onConfirm={() => this.createShipment()}
        />

        <Modal
          title={null}
          visible={this.state.shipmentModal.printModalVisible}
          onCancel={() =>
            this.setState(
              {
                shipmentModal: {
                  ...this.state.shipmentModal,
                  printModalVisible: false,
                },
              },
              () => this.props.reloadList()
            )
          }
          footer={null}
        >
          <div style={{ textAlign: "center" }}>
            <Icon
              type="check-circle"
              theme="filled"
              style={{ fontSize: 60, color: "#73D13D" }}
            />
            <br />
            <br />
            <h2 style={{ fontSize: 22 }}>Shipment Confirmed!</h2>
            <p style={{ fontSize: 18, marginBottom: 0 }}>
              <span style={{ color: "#5D5C5C" }}>A new shipment with</span>{" "}
              Shipment{" "}
              <span style={{ color: "#0068FF" }}>
                #{this.state.shipmentModal.awb}
              </span>{" "}
              has been created.
            </p>
            <p style={{ fontSize: 16 }}>
              <span style={{ color: "#818080" }}>
                Please Print and stick the shipment code on the parcel carrying
                the order items
              </span>
            </p>
            <Button
              style={{
                background: "#73D13D",
                borderColor: "#73D13D",
                color: "white",
              }}
              size="large"
              onClick={() => {
                this.setState(
                  {
                    shipmentModal: {
                      ...this.state.shipmentModal,
                      printModalVisible: false,
                    },
                  },
                  () => {
                    window.open(
                      `/orders/print_package_slip/${
                        this.state.shipmentModal.awb
                      }`
                    );
                    this.props.reloadList();
                  }
                );
              }}
            >
              Print Shipment Code
            </Button>
          </div>
        </Modal>
      </Card>
    );
  }
}

export default OrderCard;
