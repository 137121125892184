import React from "react";
import "./index.scss";
import { ReactComponent as PlaceHolder } from "../../assets/images/icons/404.svg";

const NoData = props => {
  return (
    <div className="no-table-data">
      {props.image !== undefined ? <props.image /> : <PlaceHolder />}

      <div className="text">
        <h3 className="heading">{props.heading}</h3>
        <p className="content">
          {typeof props.content === "string" && props.content.length
            ? props.content
            : "We could not find what you're looking for!"}
        </p>
      </div>
    </div>
  );
};

export default NoData;
