import React, { Component } from "react";
import {
  Row,
  Card,
  Select,
  Col,
  Input,
  Button,
  Table,
  message,
  Icon,
  Upload
} from "antd";
import "./index.scss";
import axios from "axios";

const { TextArea } = Input;
const { Dragger } = Upload;

class NewCustomProduct extends Component {
  state = {
    fileList: [],
    loading: false,
    customProduct: {
      name: "",
      description: "",
      gallery: [],
      variantPropKeys: [],
      variants: []
    },
    addVariantForm: {
      inventory: "",
      vendor_data: {
        price: "",
        hsn: "",
        weight: "",
        dimensions: {
          w: "",
          d: "",
          h: ""
        }
      }
    }
  };

  onChange(key, value) {
    this.setState({
      customProduct: {
        ...this.state.customProduct,
        [key]: value
      }
    });
  }
  onChangeVariant(key, value) {
    this.setState({
      addVariantForm: {
        ...this.state.addVariantForm,
        [key]: value
      }
    });
  }
  onChangeVendorData(key, value) {
    this.setState({
      addVariantForm: {
        ...this.state.addVariantForm,
        vendor_data: {
          ...this.state.addVariantForm.vendor_data,
          [key]: value
        }
      }
    });
  }
  onChangeVariantDimensions(key, value) {
    this.setState({
      addVariantForm: {
        ...this.state.addVariantForm,
        vendor_data: {
          ...this.state.addVariantForm.vendor_data,
          dimensions: {
            ...this.state.addVariantForm.vendor_data.dimensions,
            [key]: value
          }
        }
      }
    });
  }

  addVariant() {
    let keys;
    this.state.customProduct.variantPropKeys.map(k => (keys = k));
    this.setState({
      customProduct: {
        ...this.state.customProduct,
        variants: [
          ...this.state.customProduct.variants,
          {
            ...this.state.addVariantForm,
            title: this.state.customProduct.name,
            allspecs: this.state.customProduct.variantPropKeys
              .map(k => this.state.addVariantForm[k])
              .join(",")
          }
        ]
      },
      addVariantForm: {
        inventory: "",

        [keys]: "",
        vendor_data: {
          price: "",
          hsn: "",
          weight: "",

          dimensions: {
            w: "",
            d: "",
            h: ""
          }
        }
      }
    });
  }
  handleUpload = () => {
    const fileList = this.state.fileList;
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append("files", file);
    });

    // You can use any AJAX library you like
    axios({
      url: "/products/upload_media",
      method: "post",
      processData: false,
      data: formData,
      onUploadProgress: progressEvent => {
        this.setState({
          percentCompleted: Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
        });
      }
    })
      .then(res => {
        if (res.data.success === 1) {
          this.setState(state => ({
            customProduct: {
              ...state.customProduct,
              gallery: [...state.customProduct.gallery, res.data.url]
            }
          }));
        } else {
          message.error(res.data.msg);
        }
      })
      .catch(e => message.error(e.message));
  };

  addCustomProduct() {
    if (this.state.customProduct.variants.length === 0) {
      message.error("Please fill atleast one variant");
      return;
    }
    this.setState(
      {
        loading: true
      },
      () => {
        axios({
          url: "/products/create_custom_product",
          method: "POST",
          data: {
            data: this.state.customProduct
          }
        })
          .then(res => {
            if (res.data.success === 1) {
              this.setState({
                loading: false
              });
            } else {
              this.setState({
                loading: false
              });
            }
          })
          .catch(e => {
            this.setState({
              loading: false
            });
            message.error(e.message);
          });
      }
    );
  }

  render() {
    const columns = [
      {
        title: "Price",
        key: "price",
        dataIndex: "vendor_data",
        render: vendor_data => vendor_data.price
      },
      {
        title: "HSN",
        key: "hsn",
        dataIndex: "vendor_data",
        render: vendor_data => vendor_data.hsn
      },
      {
        title: "Weight",
        key: "weight",
        dataIndex: "vendor_data",
        render: vendor_data => vendor_data.weight
      },
      {
        title: "Dimensions",
        key: "dimensions",
        dataIndex: "vendor_data",
        render: vendor_data => (
          <p>
            {vendor_data.dimensions.w} X {vendor_data.dimensions.h} X{" "}
            {vendor_data.dimensions.d}
          </p>
        )
      },
      ...this.state.customProduct.variantPropKeys.map(k => ({
        title: k,
        key: k,
        dataIndex: k
      }))
    ];
    let DynamicInput = this.state.customProduct.variantPropKeys.map(k => (
      <Col md={12}>
        <p>{k}</p>
        <Input
          placeholder={k}
          value={this.state.addVariantForm[k]}
          onChange={e => this.onChangeVariant(k, e.target.value)}
        />
      </Col>
    ));

    const props = {
      accept: ".png,.jpg,.jpeg",
      multiple: true,
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList
          };
        });
      },
      beforeUpload: file => {
        if (
          file.type === "image/png" ||
          file.type === "image/jpg" ||
          file.type === "image/jpeg"
        ) {
          this.setState(
            state => ({
              fileList: [...state.fileList, file]
            }),
            () => this.handleUpload()
          );
          return false;
        } else {
          message.error("Please select a valid file!");
        }
      },
      fileList: this.state.fileList
    };
    return (
      <div className="custom-product">
        <Row>
          <h2>New Custom Product</h2>
        </Row>
        <Card>
          <Row>
            <Col md={12}>
              <p>Name</p>
              <Input
                placeholder="Name"
                value={this.state.customProduct.name}
                onChange={e => this.onChange("name", e.target.value)}
              />
            </Col>
            <Col md={12}>
              <p>Description</p>
              <TextArea
                placeholder="Description"
                value={this.state.customProduct.description}
                onChange={e => this.onChange("description", e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <p>Gallery Images</p>
            <div className="imageDropper">
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">
                  Drag your file here or click to browse files
                </p>
              </Dragger>
            </div>
          </Row>
          <Row>
            <p>Variant PropKeys</p>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              value={this.state.customProduct.variantPropKeys}
              onChange={value => this.onChange("variantPropKeys", value)}
            >
              {this.state.variantPropKeys}
            </Select>
          </Row>
          <Row>
            <h3> Add Variants</h3>
          </Row>
          <div className="variants">
            <Row>
              <Col md={12}>
                <p>Inventory</p>
                <Input
                  placeholder="Inventory"
                  value={this.state.addVariantForm.inventory}
                  onChange={e =>
                    this.onChangeVariant("inventory", e.target.value)
                  }
                />
              </Col>

              <Col md={12}>
                <p>Price</p>
                <Input
                  placeholder="Price"
                  value={this.state.addVariantForm.vendor_data.price}
                  onChange={e =>
                    this.onChangeVendorData("price", e.target.value)
                  }
                />
              </Col>
              <Col md={12}>
                <p>HSN</p>
                <Input
                  placeholder="HSN"
                  value={this.state.addVariantForm.vendor_data.hsn}
                  onChange={e => this.onChangeVendorData("hsn", e.target.value)}
                />
              </Col>
              <Col md={12}>
                <p>Weight</p>
                <Input
                  placeholder="Weight"
                  value={this.state.addVariantForm.vendor_data.weight}
                  onChange={e =>
                    this.onChangeVendorData("weight", e.target.value)
                  }
                />
              </Col>
              <Col md={12}>
                <p>Dimensions</p>
                <Input
                  placeholder="W"
                  value={this.state.addVariantForm.vendor_data.dimensions.w}
                  onChange={e =>
                    this.onChangeVariantDimensions("w", e.target.value)
                  }
                  style={{ width: "120px", marginRight: "21px" }}
                />
                <Input
                  placeholder="H"
                  value={this.state.addVariantForm.vendor_data.dimensions.h}
                  onChange={e =>
                    this.onChangeVariantDimensions("h", e.target.value)
                  }
                  style={{ width: "120px", marginRight: "21px" }}
                />
                <Input
                  placeholder="D"
                  value={this.state.addVariantForm.vendor_data.dimensions.d}
                  onChange={e =>
                    this.onChangeVariantDimensions("d", e.target.value)
                  }
                  style={{ width: "120px" }}
                />
              </Col>
              {DynamicInput}
              <Col md={24} className="Add-button">
                <Button onClick={() => this.addVariant()} type="primary">
                  Add Variant
                </Button>
              </Col>
            </Row>
          </div>
          <Row>
            <Table
              pagination={false}
              rowKey={(_, i) => i}
              columns={columns}
              dataSource={this.state.customProduct.variants}
              style={{ paddingBottom: 5 }}
              //   scroll={{ x: 1300 }}
            />
          </Row>
          <Row md={24} className="Add-button">
            <Button
              onClick={() => this.addCustomProduct()}
              type="primary"
              loading={this.state.loading}
            >
              Add Product
            </Button>
          </Row>
        </Card>
      </div>
    );
  }
}

export default NewCustomProduct;
