import React, { Component } from "react";
import { Alert, Skeleton, Row, Icon, Pagination, Empty, Input } from "antd";
import ProductCard from "../ProductCard";
import axios from "axios";

class ProductCatalog extends Component {
  state = {
    products: [],
    total: 0,
    loading: true,
    error: false,
    errorMsg: "",
    filters: {
      search: "",
    },
    paging: {
      page: 1,
      per_page_count: 10,
    },
  };

  fetchUsers() {
    axios({
      url: "/products/get_product_catalog",
      method: "post",
      data: {
        page: this.state.paging.page,
        per_page_count: this.state.paging.per_page_count,
        filters: this.state.filters,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            products: res.data.products,
            total: res.data.total,
            loading: false,
          });
        } else {
          this.setState({
            error: true,
            errorMsg: res.data.msg,
            loading: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.fetchUsers();
  }

  goToPage(page) {
    this.setState(
      {
        loading: true,
        paging: {
          ...this.state.paging,
          page,
        },
      },
      () => this.fetchUsers()
    );
  }

  changeFilter(k, v) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [k]: v,
        },
      },
      () => this.goToPage(1)
    );
  }

  render() {
    const PageWrapper = (children) => (
      <div className="store-users">
        <Row>
          <h2>Product Catalog</h2>
        </Row>
        <Row>
          <h2>
            <Input.Search
              style={{ width: 400 }}
              placeholder="Enter Keywords"
              enterButton="Search"
              onSearch={(value) => this.changeFilter("search", value)}
            />
            <span style={{ float: "right" }}>
              <a
                href="/products/products_catalog_csv"
                target="_blank"
                rel="noreferrer noopenr"
                className="ant-btn ant-btn-primary"
              >
                <Icon type="download" /> Download as CSV
              </a>
            </span>
          </h2>
        </Row>
        {children}
      </div>
    );

    if (this.state.loading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={this.state.errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    return PageWrapper(
      <React.Fragment>
        <div className="products-catalog">
          {this.state.products.length > 0 ? (
            this.state.products.map((p) => (
              <ProductCard key={p._id["$oid"]} catalog={true} product={p} />
            ))
          ) : (
            <Empty description="No Products Found in Catalog!" />
          )}
        </div>
        <Pagination
          showQuickJumper
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          defaultCurrent={this.state.paging.page}
          pageSize={this.state.paging.per_page_count}
          total={this.state.total}
          onChange={(page) => this.goToPage(page)}
        />
      </React.Fragment>
    );
  }
}

export default ProductCatalog;
