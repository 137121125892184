import React, { Component } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import LoginForm from "./Components/Login/LoginForm/";
import Dashboard from "./Components/Dashboard/";
import SyncProducts from "./Components/Dashboard/SyncProducts";
import ProductCatalog from "./Components/Dashboard/ProductCatalog";
import OpenOrders from "./Components/Dashboard/OpenOrders";
import Sourcing from "./Components/Dashboard/Sourcing";
import VerifiedSourcing from "./Components/Dashboard/VerifiedSourcing";
import Shipments from "./Components/Dashboard/Shipments";
import MasterShipments from "./Components/Dashboard/MasterShipments";
import Profile from "./Components/Dashboard/Profile";
import NewCustomProduct from "./Components/Dashboard/NewCustomProduct";
import NotFound404 from "./Components/NotFound404";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="content-body">
          <Switch>
            <Route
              path="/profile"
              render={props => (
                <Dashboard {...{ ...props, title: "vFulfil | My Profile" }}>
                  {" "}
                  <Profile {...props} />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/"
              render={props => (
                <Dashboard {...{ ...props, title: "vFulfill" }} />
              )}
            />
            <Route
              path="/login"
              render={props => (
                <LoginForm {...{ ...props, title: "vFulfill | Login" }} />
              )}
            />

            <Route
              exact
              path="/product-catalog"
              render={props => (
                <Dashboard
                  {...{ ...props, title: "vFulfil | Product Catalog" }}
                >
                  {" "}
                  <ProductCatalog {...props} />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/sync-products/:prodid?"
              render={props => (
                <Dashboard {...{ ...props, title: "vFulfil | Sync Products" }}>
                  {" "}
                  <SyncProducts {...props} />
                </Dashboard>
              )}
            />

            <Route
              exact
              path="/orders"
              render={props => (
                <Dashboard {...{ ...props, title: "vFulfil | All Orders" }}>
                  {" "}
                  <OpenOrders {...props} pageType="allorders" />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/shipments"
              render={props => (
                <Dashboard {...{ ...props, title: "vFulfil | Shipments" }}>
                  {" "}
                  <Shipments {...props} />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/master-shipments"
              render={props => (
                <Dashboard
                  {...{ ...props, title: "vFulfil | Master Shipments" }}
                >
                  {" "}
                  <MasterShipments {...props} />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/open-orders"
              render={props => (
                <Dashboard {...{ ...props, title: "vFulfil | Open Orders" }}>
                  {" "}
                  <OpenOrders {...props} pageType="openorders" />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/new-custom-product"
              render={props => (
                <Dashboard
                  {...{ ...props, title: "vFulfil | New Custom Product" }}
                >
                  {" "}
                  <NewCustomProduct />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/sourcing"
              render={props => (
                <Dashboard {...{ ...props, title: "vFulfil | Sourcing" }}>
                  {" "}
                  <Sourcing {...props} />
                </Dashboard>
              )}
            />
            <Route
              exact
              path="/sourcing/verified"
              render={props => (
                <Dashboard
                  {...{ ...props, title: "vFulfil | Verified Items Log" }}
                >
                  {" "}
                  <VerifiedSourcing {...props} />
                </Dashboard>
              )}
            />
            <Route path="*" render={() => <NotFound404 />} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
