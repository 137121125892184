import React, { Component } from "react";
import {
  Alert,
  Skeleton,
  Row,
  Button,
  Icon,
  Table,
  message,
  Input,
  InputNumber,
  Col,
} from "antd";
import axios from "axios";
import "./index.scss";

class Sourcing extends Component {
  state = {
    orders: [],
    selected: [],
    loading: true,
    error: false,
    errorMsg: "",
    filters: {
      search: "",
    },
    paging: {
      page: 1,
      per_page_count: 10,
      total: 0,
    },
  };

  fetchUsers() {
    axios({
      url: "/orders/get_sourcing_list",
      method: "post",
      data: {
        page: this.state.paging.page,
        per_page_count: this.state.paging.per_page_count,
        filters: this.state.filters,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          this.setState({
            orders: res.data.orders,
            paging: { ...this.state.paging, total: res.data.total },
            loading: false,
          });
        } else {
          this.setState({
            error: true,
            errorMsg: res.data.msg,
            loading: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          error: true,
          errorMsg: e.message,
          loading: false,
        });
      });
  }

  componentDidMount() {
    this.fetchUsers();
  }

  handlePageChange = (pagination) => {
    this.setState(
      {
        paging: { ...this.state.paging, page: pagination.current },
      },
      () => this.fetchUsers()
    );
  };

  setQuantity = (id, quantity) => {
    this.setState({
      orders: this.state.orders.map((o) => {
        if (o._id["$oid"] === id) {
          return {
            ...o,
            quantity,
          };
        } else {
          return o;
        }
      }),
    });
  };

  verifyQuantity = (id) => {
    const s = this.state.orders.find((o) => o._id["$oid"] === id);
    axios({
      url: "/orders/verify_sourcing_list",
      method: "post",
      data: {
        id,
        quantity: s.quantity,
      },
    })
      .then((res) => {
        if (res.data.success === 1) {
          message.success("Verified Successfully!");
          this.fetchUsers();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  changeFilter(k, v) {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [k]: v,
        },
      },
      () => this.goToPage(1)
    );
  }

  goToPage(page) {
    this.setState(
      {
        loading: true,
        paging: {
          ...this.state.paging,
          page,
        },
      },
      () => this.fetchUsers()
    );
  }
  render() {
    const PageWrapper = (children) => (
      <div className="store-users">
        <Row>
          <h2 style={{ marginBottom: 30 }}>Sourcing List</h2>
        </Row>
        <Row>
          <h2>
            <Input.Search
              style={{ width: 400 }}
              placeholder="Enter Keywords"
              enterButton="Search"
              onSearch={(value) => this.changeFilter("search", value)}
            />
            <span style={{ float: "right" }}>
              <a
                href="/orders/sourcing_csv"
                target="_blank"
                rel="noreferrer noopener"
                className="ant-btn ant-btn-primary"
              >
                <Icon type="download" /> Download as CSV
              </a>
            </span>
          </h2>
        </Row>
        {children}
      </div>
    );

    if (this.state.loading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      );
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={this.state.errorMsg}
              type="error"
            />
          </Row>
        </div>
      );
    }

    let columns = [
      {
        title: "Name",
        dataIndex: null,
        render: (_, r) => (
          <div style={{ display: "flex", alignItems: "ceneter" }}>
            <div>
              <img
                src={r.img}
                alt={r.name}
                style={{ width: "100%", maxWidth: "55px", marginRight: 20 }}
              />
            </div>
            <div className="blue-text" style={{ paddingLeft: 15 }}>
              {r.name}
            </div>
          </div>
        ),
      },
      {
        title: "Product Specs",
        dataIndex: "allspecs",
      },
      {
        title: "Product SKU",
        dataIndex: "vendorsku",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        width: 250,
        render: (text, r) => (
          <Input.Group size="compact">
            <Col xs={12}>
              <InputNumber
                value={text}
                onChange={(e) => this.setQuantity(r._id["$oid"], e)}
              />
            </Col>
            <Col xs={12}>
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={() => this.verifyQuantity(r._id["$oid"])}
              >
                Verify
              </Button>
            </Col>
          </Input.Group>
        ),
      },
    ];

    return PageWrapper(
      <Table
        className="sourcing-table"
        rowKey={(o) => o._id["$oid"]}
        pagination={{
          total: this.state.paging.total,
          pageSize: this.state.paging.per_page_count,
          current: this.state.paging.page,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={this.state.orders.filter((o) => o.quantity !== 0)}
        onChange={(paging) => this.goToPage(paging.current)}
        style={{ paddingBottom: 5 }}
      />
    );
  }
}

export default Sourcing;
