import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Layout } from "antd";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import NoData from "../NoData";
import "./Dashboard.scss";
import { ReactComponent as ErrorIcon } from "../../assets/images/icons/500.svg";
const { Content } = Layout;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <NoData
          image={ErrorIcon}
          heading="Oops! Something went wrong!"
          content="If you continue to see this error, please contact support"
        />
      );
    }

    return this.props.children;
  }
}

class Dashboard extends Component {
  state = {
    sidebarOpen: window.innerWidth > 768 ? true : false
  };

  componentWillMount() {
    document.title = this.props.title;
    this.unlisten = this.props.history.listen((location, action) => {
      if (window.innerWidth <= 768) {
        this.setState({ sidebarOpen: false });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentWillUpdate(nextProps) {
    document.title = nextProps.title;
  }

  toggleSidebar() {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen
    });
  }

  render() {
    const { auth } = this.props;

    if (!auth.logged_in) return <Redirect to="/login" />;
    if (!this.props.children) return <Redirect to={`/product-catalog`} />;
    return (
      <Layout style={{ height: "100%" }}>
        <Navbar
          user={auth.user}
          toggleSidebar={() => this.toggleSidebar()}
          toggleSwitchStoreModal={() => this.toggleSwitchStoreModal()}
          toggleAddStoreModal={() => this.toggleAddStoreModal()}
        />
        <Layout>
          <Sidebar
            {...this.props}
            user={auth.user}
            sidebarOpen={this.state.sidebarOpen}
            toggleSwitchStoreModal={() => this.toggleSwitchStoreModal()}
            toggleAddStoreModal={() => this.toggleAddStoreModal()}
          />
          <Layout>
            <Content className="dashboard-content">
              <ErrorBoundary {...this.props}>
                {this.props.children}
              </ErrorBoundary>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.auth
  };
};

export default connect(mapStateToProps)(Dashboard);
